import http from "@/utils/request";

export function getCategory() {
    return http({
        method: 'GET',
        url: `/category/getAll`,
    })
}

export function getCategoryById(cid) {
    return http({
        method: 'GET',
        url: `/category/getById/${cid}`,
    })
}

export const addCategory = (params) => {
    return http({
        method: 'POST',
        data: params,
        url: `/category/add`,
    })
}

export const updateCategory = (params) => {
    return http({
        method: 'PUT',
        data: params,
        url: `/category/update`,
    })
}

export const deleteCategory = (id) => {
    return http({
        method: 'DELETE',
        url: `/category/delete/${id}`
    })
}

export function getProduct(params) {
    return http({
        method: 'GET',
        params: params,
        url: `/product/getAll`,
    })
}

export const getProductById = (productId) => {
    return http({
        method: 'GET',
        url: `/product/details/${productId}`,
    })
}

export const addProduct = (params) => {
    return http({
        method: 'POST',
        data: params,
        url: `/product/add`,
    })
}

export const updateProduct = (params) => {
    return http({
        method: 'PUT',
        data: params,
        url: `/product/update`,
    })
}

export const payProduct = (productId) => {
    return http({
        method: 'PUT',
        url: `/product/pay/${productId}`
    })
}

export const deleteProduct = (productId) => {
    return http({
        method: 'DELETE',
        url: `/product/delete/${productId}`
    })
}