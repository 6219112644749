<template>
  <div>
    <el-card>
      <div class="mod-role">
        <el-form :inline="true" :model="pagination" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="pagination.productName" clearable placeholder="商品名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getDataList">查询</el-button>
          </el-form-item>
          <el-form-item style="position: absolute;right: 50px;">
            <el-button  type="primary" icon="el-icon-circle-plus-outline" @click="openDialog()">新增</el-button>
          </el-form-item>
        </el-form>
        <el-table
            :data="productList" border
            style="width: 100%"
            :cell-style="{ textAlign: 'center' }"
            :header-cell-style="{textAlign: 'center'}"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-table-column prop="productId" label="PID" width="120">
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" width="150">
          </el-table-column>
          <el-table-column prop="imageUrl" label="商品图片" width="100">
            <template slot-scope="scope">
              <img :src= "scope.row.imageUrl" width= "60" height= "60"/>
            </template>
          </el-table-column>
          <el-table-column prop="category.categoryName" label="类别" width="100">
          </el-table-column>
          <el-table-column prop="price" label="价格" width="100">
          </el-table-column>
          <el-table-column prop="stockQuantity" label="库存" width="100">
          </el-table-column>
          <el-table-column prop="description" label="商品描述" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.description}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="handleDel(scope.row.productId)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--分页模型-->
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pagination.page"
            :page-sizes="[6,8,10,15]"
            :page-size="pagination.pageSize"
            :total="totalPage"
            layout="total,sizes,prev, pager, next,jumper"
            style="margin-top: 20px;text-align: center;"
            :hide-on-single-page="totalPage<6"
            background>
        </el-pagination>
      </div>
    </el-card>

    <!--新增对话框-->
    <el-dialog title="新增商品信息" :visible.sync="addDialogFormVisible" :before-close="closeDialog">
      <el-form :model="addProductInfo">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" :label-width="formLabelWidth">
              <el-input v-model="addProductInfo.productName" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品分类" :label-width="formLabelWidth">
              <el-select v-model="addProductInfo.categoryId">
                <el-option v-for="item in categoryList"
                           :key="item.categoryId"
                           :label="item.categoryName"
                           :value="item.categoryId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品价格" :label-width="formLabelWidth">
              <el-input type="number" v-model="addProductInfo.price" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品库存" :label-width="formLabelWidth">
              <el-input type="number" v-model="addProductInfo.stockQuantity" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品图片" :label-width="formLabelWidth">
              <el-upload
                  class="avatar-uploader"
                  action="lei"
                  :on-change="handleChange"
                  :show-file-list="false"
                  :http-request="httpRequest"><!--覆盖默认上传-->
                <img v-if="imageUrl" :src="imageUrl" width="100px" height="100px" class="avatar">
                <el-button v-else icon="el-icon-plus avatar-uploader-icon">选择图片</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="商品图片路径" prop="imageUrl" hidden :label-width="formLabelWidth">
              <el-input v-model="addProductInfo.imageUrl" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="商品详情" prop="description" :label-width="formLabelWidth">
              <el-input v-model="addProductInfo.description" type="textarea"
                        :rows="2" placeholder="请输入产品介绍"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="addProduct">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改商品信息" :visible.sync="dialogFormVisible" :before-close="cancel">

      <el-form :model="productinfo" ref="productinfo">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" :label-width="formLabelWidth">
              <el-input v-model="productinfo.productName" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品分类" :label-width="formLabelWidth">
              <el-select v-model="productinfo.categoryId" placeholder="请选择商品分类">
                <el-option v-for="item in categoryList"
                           :key="item.categoryId"
                           :label="item.categoryName"
                           :value="item.categoryId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="商品价格" :label-width="formLabelWidth">
              <el-input v-model="productinfo.price" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品库存" :label-width="formLabelWidth">
              <el-input v-model="productinfo.stockQuantity" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="商品图片" :label-width="formLabelWidth">
              <img :src="productinfo.imageUrl" width="100px" height="100px">
              <el-upload
                  class="avatar-uploader"
                  action="lei"
                  :on-change="handleChange"
                  :show-file-list="false"
                  :http-request="replaceImg"><!--覆盖默认上传-->
                <el-button icon="el-icon-refresh">选择图片</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="商品详情" :label-width="formLabelWidth">
              <el-input v-model="productinfo.description" type="textarea"
                        :rows="2" placeholder="请输入产品介绍"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {addProduct, deleteProduct, getCategory, getProduct, updateProduct} from "@/api/product/product";
import {deleteImg, uplodadImg} from "@/api/upload/fileImg";

export default {
  name:'Mall',
  data() {
    return {
      productList:[],
      categoryList:[],
      pagination:{// 分页相关模型数据
        page: 1,
        pageSize: 6,
        productName: '',
      },
      totalPage: 0,//总记录数
      productinfo:{
        category:{}
      },
      rawData:{},
      diffData:{},
      dialogFormVisible:false,
      formLabelWidth: '100px',
      descriptionSize: 18,
      addDialogFormVisible:false,
      addProductInfo:{
        productName:'',
        description:'',
        imageUrl:'',
        categoryId:0,
        price:0.0,
        stockQuantity:0,
      },
      imageUrl: '',
      tempUrl: '',
      loading:true,
      defaultImg:'product_default.jpg'
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList(){
      this.loading = true
      getProduct(this.pagination).then(res => {
        if(res.data.code === 200){
          this.productList = res.data.data
          this.totalPage = parseInt(res.data.message)
          this.loading = false
        }
      })
      getCategory().then(res=>{
        if(res.data.code === 200){
          this.categoryList = res.data.data
        }
      })
    },
    sizeChangeHandle(val){
      this.pagination.pageSize = val;
      this.getDataList();
    },
    currentChangeHandle(val){
      this.pagination.page = val;
      this.getDataList();
    },
    openDialog(){
      this.addDialogFormVisible = true
      this.addProductInfo = {
        productName:'',
        description:'',
        imageUrl:'',
        categoryId:'',
        price:0.0,
        stockQuantity:0,
      }
    },
    closeDialog(){
      this.addDialogFormVisible = false
      if(this.addProductInfo.imageUrl!= ''){
        var str = this.addProductInfo.imageUrl
        deleteImg(str).then(res=>{
          this.dataReload()
        })
      }
    },
    dataReload() {
      // 重新获取数据
      setTimeout(() => {
        window.location.reload()
      }, 200)
    },
    //将上传图片的原路径赋值给临时路径
    handleChange(file, fileList) {
      this.tempUrl = URL.createObjectURL(file.raw);
    },
    //实现图片上传功能
    httpRequest(item) {
      //验证图片格式大小信息
      const isJPG = item.file.type == 'image/jpeg' || item.file.type == 'image/png';
      const isLt2M = item.file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      //图片格式大小信息没问题 执行上传图片的方法
      if (isJPG && isLt2M == true) {
        //定义FormData对象 存储文件
        let mf = new FormData();
        //将图片文件放入mf
        mf.append('file', item.file);
        this.imageUrl = this.tempUrl
        this.productinfo.imageUrl = this.imageUrl
        uplodadImg(mf).then(res=>{
          if(res.data.code == 200){
            this.$message({
              message: '上传成功',
              type: 'success'
            });
            this.imageUrl = this.tempUrl
            this.addProductInfo.imageUrl = res.data.message
          }else {
            this.$message({
              message: '上传失败',
              type: 'error'
            });
          }
        })
      }
    },
    addProduct(){
      if (this.addProductInfo.productName === '') {
        this.$notify({
          title: '警告',
          message: '商品名称不能为空,请输入商品名称',
          type: 'warning'
        });
        return
      }
      if (this.addProductInfo.price === '') {
        this.$notify({
          title: '警告',
          message: '商品价格不能为空,请输入商品价格',
          type: 'warning'
        });
        return
      }
      if (this.addProductInfo.stockQuantity === '') {
        this.$notify({
          title: '警告',
          message: '商品库存不能为空,请输入商品库存',
          type: 'warning'
        });
        return
      }
      if (this.addProductInfo.imageUrl === '') {
        this.$notify({
          title: '警告',
          message: '商品图片不能为空,请选择商品图片',
          type: 'warning'
        });
        return
      }
      if (this.addProductInfo.description === '') {
        this.$notify({
          title: '警告',
          message: '商品描述不能为空,请输入商品描述',
          type: 'warning'
        });
        return
      }
      if (this.addProductInfo.categoryId <= 0 || this.addProductInfo.price <= 0 || this.addProductInfo.stockQuantity <= 0) {
        this.$notify({
          title: '警告',
          message: '输入格式错误,请重新输入',
          type: 'warning'
        });
        return
      }
      this.addDialogFormVisible = false
      addProduct(this.addProductInfo).then(res => {
        if(res.data.code === 200){
          this.$message({
            message: "添加成功",
            type: 'success'
          })
        } else {
          this.$message({
            message: res.data.message,
            type:'error'
          })
          this.closeDialog()
        }
      })
      this.dataReload()
    },
    cancel() {
      this.dialogFormVisible = false
      this.$message({
        message: '取消修改',
        type: 'warning'
      });
    },
    diffFormData () {
      for (let k in this.rawData) {
        if (this.rawData[k] !== this.productinfo[k]) {
          if (!this.diffData) {
            this.diffData = {}
          }
          this.diffData[k] = this.productinfo[k]
        }
      }
    },
    handleEdit(row){
      this.dialogFormVisible = true
      this.productinfo = row;
      this.rawData = { ...row }
    },
    sure(){
      this.dialogFormVisible = false
      this.diffFormData()
      if (!this.diffData.productName && !this.diffData.description &&
          !this.diffData.categoryId && !this.diffData.price &&
          !this.diffData.stockQuantity && !this.diffData.imageUrl) {
        this.$message.warning('未修改任何数据，无需提交')
      } else {
        this.diffData.productId = this.productinfo.productId
        // console.log(this.diffData)
        updateProduct(this.diffData).then(res => {
          if(res.data.code === 200){
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: '修改失败',
              type:'error'
            })
          }
        })
      }
      this.dataReload()
    },
    replaceImg(item){
      //验证图片格式大小信息
      const isJPG = item.file.type == 'image/jpeg' || item.file.type == 'image/png';
      const isLt2M = item.file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      //图片格式大小信息没问题 执行上传图片的方法
      if (isJPG && isLt2M == true) {
        //定义FormData对象 存储文件
        let mf = new FormData();
        //将图片文件放入mf
        mf.append('file', item.file);
        uplodadImg(mf).then(res=>{
          if(res.data.code == 200){
            this.$message({
              message: '上传成功',
              type: 'success'
            });
            let imgUrl = this.productinfo.imageUrl
            let index = imgUrl.indexOf("blog/");
            if (index!== -1) {
              imgUrl = imgUrl.substring(index+5);
            }
            // console.log(imgUrl)
            if (this.defaultImg !== imgUrl){
              deleteImg(imgUrl).then(res=>{})
            }
            this.productinfo.imageUrl = res.data.message
          }else {
            this.$message({
              message: '上传失败',
              type: 'error'
            });
          }
        })
      }
    },
    handleDel(pid){
      deleteProduct(pid).then(res => {
        if(res.data.code === 200){
          this.$message({
            message: "删除成功",
            type: 'success'
          })
        } else {
          this.$message({
            message: res.data.message,
            type:'error'
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>