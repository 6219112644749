import http from "@/utils/request";

export function isCollect(blogId) {
    return http({
        method: 'GET',
        url: `/user/collect/or/not/${blogId}`
    })
}

export function addCollect(blogId) {
    return http.post(`/user/collect`, {blogId})
}