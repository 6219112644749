import Vue from 'vue'
import VueRouter from "vue-router";
import Home from '@/views/Home.vue'
import User from '@/views/Users/User.vue'
import Main from "@/views/Main.vue";
import Mall from "@/views/Mall.vue";
import PageOne from "@/views/PageOne.vue";
import PageTwo from "@/views/PageTwo.vue";
import Login from "@/LoginAndRegister/Login.vue";
import {Message} from "element-ui";
import Register from "@/LoginAndRegister/Register.vue";
import BlogList from "@/views/Blog/BlogList.vue";
import MyBlog from "@/views/Blog/MyBlog.vue";
import WriterMarkdown from "@/components/WriterMarkdown.vue";
import ShowBlog from "@/components/ShowBlog.vue";
import Me from "@/views/Users/Me/Me.vue";
import Index from "@/views/Front/Index.vue";
import AllBlog from "@/views/Front/AllBlog.vue";
import Rank from "@/views/Front/Rank.vue";
import Product from "@/views/Front/Product.vue";
import ShowProduct from "@/components/ShowProduct.vue";
import Category from "@/views/Category.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
    return VueRouterReplace.call(this, to).catch(err => err);
}

const routes = [
    // 主路由
    {
        path: '/',
        component: Main,
        redirect: '/home',
        children:[
            // 子路由
            {
                path: 'home',
                name:'home',
                component: Home,
                meta:{title:'每日Java | 首页',requireAuth:true}
            },
            {
                path: 'user',
                name:'user',
                component: User,
                meta:{title:'每日Java | 用户列表',requireAuth:true}
            },
            {
                path: 'me',
                name:'me',
                component: Me,
                meta:{title:'每日Java | 个人中心',requireAuth:true}
            },
            {
                path: 'mall',
                name:'mall',
                component: Mall,
                meta:{title:'每日Java | 商品管理',requireAuth:true}
            },
            {
                path: 'categoty',
                name:'categoty',
                component: Category,
                meta:{title:'每日Java | 类别管理',requireAuth:true}
            },
            {
                path: 'blogList',
                name:'blogList',
                component: BlogList,
                meta:{title:'每日Java | 博客列表',requireAuth:true}
            },
            {
                path: 'myBlog',
                name:'myBlog',
                component: MyBlog,
                meta:{title:'每日Java | 我的博客',requireAuth:true}
            },
            {
                path: 'showBlog',
                name:'showBlog',
                component: ShowBlog,
                meta:{title:'每日Java | 博客详情',requireAuth:true}
            },
            {
                path: 'writerMarkdown',
                name:'writerMarkdown',
                component: WriterMarkdown,
                meta:{title:'每日Java | 编写博客',requireAuth:true}
            },
            {
                path: 'page1',
                name:'page1',
                component: PageOne,
                meta:{title:'每日Java | 测试页面1',requireAuth:true}
            },
            {
                path: 'page2',
                name:'page2',
                component: PageTwo,
                meta:{title:'每日Java | 测试页面2',requireAuth:true}
            },
        ]
    },
    {
        path: '/front',
        component: Index,
        redirect: '/front/index',
        children: [
            {
                path: 'index',
                name:'index',
                component: AllBlog,
                meta:{title:'每日Java | 首页',requireAuth:true}
            },
            {
                path: 'writerMarkdown',
                name:'writerMarkdown',
                component: WriterMarkdown,
                meta:{title:'每日Java | 编写博客',requireAuth:true}
            },
            {
                path: 'me',
                name:'me',
                component: Me,
                meta:{title:'每日Java | 个人中心',requireAuth:true}
            },
            {
                path: 'myBlog',
                name:'myBlog',
                component: MyBlog,
                meta:{title:'每日Java | 我的博客',requireAuth:true}
            },
            {
                path: 'showBlog',
                name:'showBlog',
                component: ShowBlog,
                meta:{title:'每日Java | 博客详情',requireAuth:true}
            },
            {
                path: 'rank',
                name:'rank',
                component: Rank,
                meta:{title:'每日Java | 排行榜',requireAuth:true}
            },
            {
                path: 'product',
                name:'product',
                component: Product,
                meta:{title:'每日Java | 商品周边',requireAuth:true}
            },
            {
                path: 'showProduct',
                name:'showProduct',
                component: ShowProduct,
                meta:{title:'每日Java | 商品详情',requireAuth:true}
            },
        ]
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/register',
        component: Register
    }

]


const router = new VueRouter({
    routes
})

// 暴露之前先添加一个路由守卫
// 全局前置路由守卫 => 在切换之前进行调用|初始化之前调用
// to来自哪里 from去哪里 next()放行
router.beforeEach((to, from, next) => {
    // 判断页面是否为需要登录才能访问的页面
    if (to.meta.requireAuth) {
        //判断token是否存在
        if (localStorage.getItem("MRtoken") !== null) {
            // 存在直接放行
            next();
        } else {
            // 不存在跳转到登录页面
            Message.warning("无权操作 请先登录 !")
            next({path: '/login'});
        }
    } else {
        next();
    }
})

export default router