import http from "@/utils/request";

export function getAllBlog(params) {
    return http({
        method: 'GET',
        params: params,
        url: `/blog/getAll`,
    })
}

export function getMyBlog(params) {
    return http({
        method: 'GET',
        params: params,
        url: `/blog/me`,
    })
}

export function getBlogByBlogId(blogId) {
    return http({
        method: 'GET',
        url: `/blog/article/${blogId}`
    })
}

export function likeBlogByBlogId(blogId) {
    return http({
        method: 'PUT',
        url: `/blog/like/${blogId}`
    })
}

export function isDidStar(blogId) {
    return http({
        method: 'GET',
        url: `/blog/is-like/${blogId}`
    })
}

export function addWatch(blogId) {
    return http({
        method: 'PUT',
        url: `/blog/watch/${blogId}`
    })
}

export function addBlog(blog) {
    return http({
        method: 'POST',
        url: `/blog/add`,
        data: blog
    })
}

export function updateBlogByLoginUser(blog) {
    return http.put('/blog/update', blog)
}

export function deleteByBlogInLoginUser(blogId) {
    return http({
        method: 'delete',
        url: `/blog/delete/${blogId}`
    })
}