import http from "@/utils/request";

export const uplodadImg = (file) => {
    return http({
        method: 'POST',
        url: `/oss/upload`,
        data: file
    })
}

export const deleteImg = (imgname) => {
    return http({
        method: 'POST',
        url: `/oss/delete/${imgname}`
    })
}

export function downloadImg(filename) {
    return http({
        method: 'GET',
        url: `/oss/download/${filename}`,
    })
}