<template>
  <div>
    <el-card>
      <div class="mod-role">
        <el-form :inline="true" :model="pagination" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="pagination.username" clearable placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getDataList">查询</el-button>
          </el-form-item>
          <el-form-item style="position: absolute;right: 50px;">
            <el-button  type="primary" icon="el-icon-circle-plus-outline" @click="openDialog()">新增</el-button>
          </el-form-item>
        </el-form>
        <el-table
            :data="userList" border
            style="width: 100%"
            :cell-style="{ textAlign: 'center' }"
            :header-cell-style="{textAlign: 'center'}"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-table-column prop="uid" label="ID" width="60">
          </el-table-column>
          <el-table-column prop="username" label="用户名" width="150">
          </el-table-column>
          <el-table-column prop="headurl" label="头像" width="100">
            <template slot-scope="scope">
              <img :src= "scope.row.headurl" width= "60" height= "60"/>
            </template>
          </el-table-column>
          <el-table-column prop="email" label="邮箱" width="180">
          </el-table-column>
          <el-table-column prop="identity" label="身份" width="120">
          </el-table-column>
          <el-table-column prop="label" label="标签" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag style="margin-left: 5px"
                  v-for="(item,index) in scope.row.label"
                  :key="index"
                  effect="dark">
                {{ item }}，
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="gender" label="性别" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.gender == 1 ? '男' : '女' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="birthday" label="生日" width="120">
          </el-table-column>
          <el-table-column prop="schoolOrCorporation" label="学校" width="180">
          </el-table-column>
          <el-table-column prop="description" label="个人描述" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.description}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button size="mini" type="primary"
                         @click="handleEdit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--分页模型-->
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pagination.page"
            :page-sizes="[6,8,10,15]"
            :page-size="pagination.pageSize"
            :total="totalPage"
            layout="total,sizes,prev, pager, next,jumper"
            style="margin-top: 20px;text-align: center;"
            :hide-on-single-page="totalPage<6"
            background>
        </el-pagination>
      </div>
    </el-card>

    <!--修改对话框-->
    <el-dialog title="修改用户信息" :visible.sync="dialogFormVisible">

      <el-form :model="userinfo">

        <el-form-item label="用户名称" :label-width="formLabelWidth">
          <el-input v-model="userinfo.username"
                    disabled
                    autocomplete="off"></el-input>
        </el-form-item>

        <el-row>
          <el-col :span="7">
            <el-form-item label="身份" :label-width="formLabelWidth">
              <el-select v-model="userinfo.identity" placeholder="请选择身份">
                <el-option label="学生" value="学生"></el-option>
                <el-option label="打工人" value="打工人"></el-option>
                <el-option label="码农" value="码农"></el-option>
                <el-option label="开发人员" value="开发人员"></el-option>
                <el-option label="测试人员" value="测试人员"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="7">
            <el-form-item label="性别" :label-width="formLabelWidth">
              <el-select v-model="userinfo.gender" placeholder="请选择">
                <el-option label="男" :value=1></el-option>
                <el-option label="女" :value=0></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="生日" :label-width="formLabelWidth">
              <el-date-picker
                  v-model="userinfo.birthday"
                  type="date"
                  format="yyyy-MM-dd"
                  placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="学校/公司" :label-width="formLabelWidth">
          <el-input v-model="userinfo.schoolOrCorporation"
                    maxlength="25"
                    show-word-limit
                    autocomplete="off"></el-input>
        </el-form-item>


        <!--擅长的语言选择多系列-->
        <el-form-item label="擅长的语言" :label-width="formLabelWidth">
          <el-tag
              size="small"
              :key="tag"
              v-for="tag in userinfo.label"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
            {{ tag }}
          </el-tag>

          <el-input
              :disabled="userinfo.label.length === 6"
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增标签</el-button>
        </el-form-item>

        <hr>
        <div style="margin-left: 18px;margin-bottom: 20px;">个人描述
          <div style="float: right">
            字体大小&nbsp;&nbsp;&nbsp;
            <el-button size="mini" @click="descriptionSize++">+</el-button>
            <el-button size="mini" @click="descriptionSize--">-</el-button>
          </div>
        </div>
        <el-input
            type="textarea"
            :style="{fontSize: descriptionSize + 'px'}"
            :autosize="{ minRows: 8, maxRows: 20}"
            placeholder="请输入个人描述"
            maxlength="1500"
            show-word-limit
            v-model="userinfo.description">
        </el-input>


      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </div>
    </el-dialog>

    <!--新增对话框-->
    <el-dialog title="新增用户信息" :visible.sync="addDialogFormVisible">

      <el-form :model="addUserInfo">

        <el-form-item label="邮箱" :label-width="formLabelWidth">
          <el-input v-model="addUserInfo.email" autocomplete="off" clearable></el-input>
        </el-form-item>

        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="addUserInfo.username" autocomplete="off" clearable></el-input>
        </el-form-item>

        <el-form-item label="密码" :label-width="formLabelWidth">
          <el-input v-model="addUserInfo.password" autocomplete="off" clearable show-password></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {addBaseUser, getAllUser, UpdateUserInfoByUid} from "@/api/user/user";
import index from "vuex";

export default {
  name:'User',
  computed: {
    index() {
      return index
    }
  },
  data() {
    return {
      userList:[],
      pagination:{// 分页相关模型数据
        page: 1,
        username: '',
        pageSize: 6,
      },
      totalPage: 0,//总记录数
      userinfo:{},
      addUserInfo:{
        username:'',
        password:'',
        email:''
      },
      dialogFormVisible:false,
      addDialogFormVisible:false,
      formLabelWidth: '100px',
      descriptionSize: 18,
      inputVisible: false,
      inputValue: '',
      loading:true
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    getDataList(){
      this.loading = true
      getAllUser(this.pagination).then(res => {
        if(res.data.code === 200){
          this.userList = res.data.data
          this.totalPage = parseInt(res.data.message)
          this.loading = false
        }
      })
    },
    sizeChangeHandle(val){
      this.pagination.pageSize = val;
      this.getDataList();
    },
    currentChangeHandle(val){
      this.pagination.page = val;
      this.getDataList();
    },
    handleEdit(row){
      this.dialogFormVisible = true
      this.userinfo = row;
    },
    // 标签的添加
    handleClose(tag) {
      this.userinfo.label.splice(this.userinfo.label.indexOf(tag), 1);
    },
    // 自动获取焦点
    showInput() {
      this.inputVisible = true;
      this.$nextTick( () => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.userinfo.label.push(inputValue);
      }

      if (inputValue.length >= 10) {
        this.$notify({
          title: '警告',
          message: '标签长度不超过10',
          type: 'warning'
        });
        this.userinfo.label.pop()
        return
      }

      if (this.userinfo.label.length >= 6) {
        this.$notify({
          title: '警告',
          message: '最多可以添加6个标签',
          type: 'warning'
        });
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    cancel() {
      this.dialogFormVisible = false
      this.$message({
        message: '取消修改',
        type: 'warning'
      });
      this.dataReload()
    },
    sure(){
      this.addDialogFormVisible = false
      // console.log(this.addUserInfo)
      UpdateUserInfoByUid(this.addUserInfo).then(res => {
        if(res.data.code === 200){
          this.$message({
            message: '修改成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: '修改失败',
            type:'error'
          })
        }
      })
      this.dataReload()
    },
    dataReload() {
      // 重新获取数据
      setTimeout(() => {
        window.location.reload()
      }, 200)
    },
    openDialog(){
      this.addDialogFormVisible = true
      this.addUserInfo = {
        username: '',
        password: '',
        email: ''
      }
    },
    closeDialog(){
      this.addDialogFormVisible = false
      this.dataReload()
    },
    addUser(){
      if (this.addUserInfo.email === '') {
        this.$notify({
          title: '警告',
          message: '邮箱号不能为空,请输入邮箱号',
          type: 'warning'
        });
        return
      }
      if (this.addUserInfo.username === '') {
        this.$notify({
          title: '警告',
          message: '用户名不能为空,请输入用户名',
          type: 'warning'
        });
        return
      }
      if (this.addUserInfo.password === '') {
        this.$notify({
          title: '警告',
          message: '密码不能为空,请输入密码',
          type: 'warning'
        });
        return
      }
      if (!/^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/.test(this.addUserInfo.email)) {
        this.$notify.error({
          title: '错误',
          message: '你输入的邮箱号不合法,请重写输入'
        });
        return;
      }
      this.addDialogFormVisible = false
      addBaseUser(this.addUserInfo).then(res => {
        if(res.data.code === 200){
          this.$message({
            message: res.data.message,
            type: 'success'
          })
        } else {
          this.$message({
            message: res.data.message,
            type:'error'
          })
        }
      })
      this.dataReload()
    },
  },
  mounted() {

  },
}
</script>

<style lang="less" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>