<template>
  <div>
    <el-backtop :visibility-height="200"></el-backtop>
    <div style="display: flex;justify-content: space-between;">
      <div
          v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-timeline style="margin-left:-30px;padding-top: 25px">
          <el-timeline-item  v-for="(item,index) in bloginfo" :key="index" :timestamp="item.releaseTime" placement="top">
            <el-card>
              <div class="blog_content">
                <div class="blog_content_left">
                  <h3 @click="goto(item.blogId)" style="cursor: pointer;text-decoration-line:underline">{{ item.title }}</h3>
                  <br>
                  <div class="blog_content_text">
<!--                    <span v-html="marked(item.context)"></span>-->
                    <span>{{ item.context }}</span>
                  </div>
                  <div class="blog_content_info">
                    <div class="blog_content_info_left">
                      <div class="blog_content_info_left_top">
                        <div>
                          <el-avatar style="vertical-align: middle" size="small" :src="userinfo.headurl"></el-avatar>
                          <span class="blog_content_info_left_user">{{ userinfo.username }}</span>
                        </div>
                        <div class="blog_content_info_left_item" style="margin-top: 5px">
                          <el-icon class="el-icon-date" style="width:15px"></el-icon>
                          <span>{{ item.releaseTime }}</span>
                        </div>
                      </div>
                      <div class="blog_content_info_left_bottom">
                        <div class="blog_content_info_left_item">
                          <el-icon class="el-icon-chat-dot-square" style="width: 15px"></el-icon>
                          <span>{{ item.comment }}</span>
                        </div>
                        <div class="blog_content_info_left_item">
                          <el-icon class="el-icon-view" style="width: 15px"></el-icon>
                          <span>{{ item.watch }}</span>
                        </div>
                        <div class="blog_content_info_left_item">
                          <el-icon class="el-icon-star-off" style="width: 15px"></el-icon>
                          <span>{{ item.star }}</span>
                        </div>
                        <div class="blog_content_info_left_item">
                          <el-icon class="el-icon-collection" style="width: 15px"></el-icon>
                          <span >{{ item.collection }}</span>
                        </div>
                      </div>

                    </div>
                    <div class="blog_content_info_right">
                      <el-tag style="color: #00B5AD;border-color: #00B5AD" size="medium" effect="plain">{{ item.label }}</el-tag>
                    </div>
                  </div>
                </div>
                <div class="blog_content_right">
                  <img :src="item.cover" @click="goto(item.blogId)" style="cursor: pointer;" alt="">
                </div>
              </div>
            </el-card>
          </el-timeline-item>
          <div v-if ='isShow'>
            <div v-if="totalPage <= 0" class="search-empty">
              <p>创作属于自己的第一篇文章</p>
              <el-button type="primary" round style="margin-top: 20px;width: 200px;font-size: 18px" @click="$router.push('/front/writerMarkdown')">写博客</el-button>
            </div>
            <div v-else>
              <el-divider><span style="font-size: 26px;font-weight: 600">没有更多了</span></el-divider>
            </div>
          </div>
        </el-timeline>
      </div>
<!--      标签-->
      <div style="margin-left: 30px">
        <el-card class="search">
          <div slot="header" class="clearfix">
            <span><el-icon class="el-icon-search"></el-icon> 搜索</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="resetSearch">
              <el-icon class="el-icon-refresh"></el-icon>
              重置
            </el-button>
          </div>
          <div>
            <el-input placeholder="Search...." v-model="pagination.title" clearable>
              <el-button slot="append" icon="el-icon-search" @click="getMyBlog"></el-button>
            </el-input>
          </div>
        </el-card>

        <el-card class="label">
          <div slot="header" class="clearfix">
            <span><el-icon class="el-icon-s-management"></el-icon> 标签</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="resetLabel">
              <el-icon class="el-icon-refresh"></el-icon>
              全部
            </el-button>
          </div>
          <div v-for="item in options" :key="item.label">
            <el-badge :value="item.value" class="label_badge">
              <el-tag class="label_tag" @click="getBlogByLabel(item.label)">{{ item.label }}</el-tag>
            </el-badge>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import {getMyBlog} from "@/api/blog/blog";
import {getLabelCount} from "@/api/count/labelCount";
import {marked} from "marked";

export default {
  name: 'MyBlog',
  data() {
    return {
      userinfo:JSON.parse(window.localStorage.getItem('userInfo')),
      bloginfo:[],
      pagination:{// 分页相关模型数据
        page: 1,
        pageSize: 6,
        label: '',
        title:''
      },
      totalPage:0,
      isShow:false,
      options: [],
      busy:false,
      loading: true
    }
  },
  created() {
    this.getMyBlog()
    this.getLabelCount()
  },
  mounted () {
    // 事件监听
    window.addEventListener('scroll', this.listenBottomOut)
  },
  destroyed () {
    // 离开页面取消监听
    window.removeEventListener('scroll', this.listenBottomOut, false)
  },
  methods: {
    marked,
    getLabelCount(){
      getLabelCount().then(res => {
        this.options = res.data.data
        this.loading = false
      })
    },
    getMyBlog(){
      this.pagination.page = 1
      this.isShow = false
      getMyBlog(this.pagination).then(res => {
        // console.log(this.pagination)
        this.bloginfo = res.data.data
        this.totalPage = parseInt(res.data.message)
        if (this.totalPage < 6){
          this.isShow = true
        }
      })
    },
    resetSearch(){
      this.pagination.title =''
      this.getMyBlog()
    },
    resetLabel(){
      this.pagination.label =''
      this.getMyBlog()
    },
    // 触底触发函数
    listenBottomOut () {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        const clientHeight = document.documentElement.clientHeight
        const scrollHeight = document.documentElement.scrollHeight-10
        if (scrollTop + clientHeight>= scrollHeight) {
          // 没有数据后，不触发请求
          if (!this.isShow) {
            this.pagination.page++
            getMyBlog(this.pagination).then(res => {
              //向上取整
              if (Math.ceil(this.totalPage / 6) <= this.pagination.page) {
                this.isShow = true
              }
              this.bloginfo = [...this.bloginfo, ...res.data.data]
            })
          }
        }
      },200)
    },
    goto(id){
      this.$router.push({
        path: '/front/showBlog',
        query: {blogId: id}
      })
    },
    getBlogByLabel(label){
      this.pagination.label = label
      getMyBlog(this.pagination).then(res => {
        this.bloginfo = res.data.data
        // console.log(this.bloginfo)
        this.totalPage = parseInt(res.data.message)
        if (this.totalPage < 6){
          this.isShow = true
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.blog_content{
  display: flex;
  justify-content: space-between;
  .blog_content_left{
    h3{
      font-family: STSong;
      font-weight: 900;
      font-size: x-large;
    }
    .blog_content_text{
      width:430px;
      height: 90px;
      font-size: 16px;
      font-weight: 300 !important;
      letter-spacing: 1px !important;
      line-height: 1.8;
      color: rgba(0,0,0,0.6);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .blog_content_info{
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      .blog_content_info_left{
        width: 80%;
        display: flex;
        flex-direction: column;
        font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
        font-weight: 700;
        color: rgba(0,0,0,.4);
        .blog_content_info_left_user{
          margin-left: 10px;
          color: #4183C4;
          cursor: pointer;
        }
        .blog_content_info_left_top{
          display: flex;
          flex-direction: row;
          padding-bottom: 10px;
          margin-left: 10px;
        }
        .blog_content_info_left_bottom{
          display: flex;
          flex-direction: row;
          padding: 10px 0;
        }
        .blog_content_info_left_item{
          margin-left: 15px;
        }
      }
      .blog_content_info_right{
        width: 20%;
        text-align:right;
        line-height: 74px;
        margin-right: 10px;
      }
    }
  }
  .blog_content_right{
    img{
      width: 288px;
      height: 180px;
      border-radius: 5px;
      margin-left: 20px;
      transition: all 0.8s;
    }
    img:hover{
      transform: scale(1.1);
    }
  }
}
.search-empty{
  width: 1100px;
  display: block;
  background-image: url(//s1.hdslb.com/bfs/static/jinkela/space/assets/nodata02.png);
  background-position: center 120px;
  background-repeat: no-repeat;
  border-radius: 4px;
  color: #6d757a;
  font-size: 14px;
  overflow: hidden;
  text-align: center;
  padding: 340px 0 80px;
}
.search{
  width: 260px;
  margin-right: 100px;
  margin-top: 25px;
}
.label{
  width: 260px;
  margin-right: 100px;
  margin-top: 30px;
  .label_badge{
    margin-top: 15px;
    margin-right: 40px;
    cursor: pointer;
  }
  .label_tag{
    width: 120px;
    text-align: center;
    font-size: 14px;
  }
}

</style>