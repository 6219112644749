<template>
  <div class="container">
    <div class="container-left">
      <img src="./images/学习.svg" alt="">
    </div>
    <div class="container-right">
      <div class="logo-container">
        <img src="./images/logo.png" alt="">
      </div>
      <div class="container-bottom">
        <el-form :inline="true" label-width="70px" class="login-container" :model="loginInfo" :rules="rules">
          <h3 class="login-title">欢迎登录</h3>
          <el-form-item prop="email">
            <el-input placeholder="请输入邮箱地址" v-model="loginInfo.email" style="width: 328px;" clearable>
              <template slot="prepend">
                <i class="el-icon-message"> 邮箱</i>
              </template>
            </el-input>
<!--            <el-input v-model="loginInfo.email" placeholder="请输入邮箱地址" clearable></el-input>-->
          </el-form-item>
          <el-form-item prop="password">
            <el-input placeholder="请输入密码" v-model="loginInfo.password" style="width: 328px;" clearable show-password>
              <template slot="prepend">
                <i class="el-icon-key"> 密码</i>
              </template>
            </el-input>
<!--            <el-input type="password" v-model="loginInfo.password" placeholder="请输入密码" clearable></el-input>-->
          </el-form-item>
          <el-form-item prop="Vcode">
            <el-input placeholder="请输入验证码" v-model="Vcode" style="width: 215px;" clearable>
              <template slot="prepend" >
                <span style="width: 70px;"> 验证码</span>
              </template>
            </el-input>
            <img :src="imgUrl" id="codeImg" @click="resetcode" class="vcode" alt="">
          </el-form-item>
          <el-form-item style="width: 350px;">
            <el-button class="login_button" type="primary" @click="login">登录</el-button>
          </el-form-item>
          <el-form-item >
            <div style="display: flex;align-items: center">
              <a class="gotoregister" @click="goToRegister">忘记密码?</a>
              <p class="separator"></p>
              <a class="register" @click="goToRegister"> 立即注册</a>
            </div>

          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {isLogin} from "@/api/user/user";


export default {
  name: 'Login',
  data() {
    return {
      logoShow: false,
      date :new Date().getTime(),
      imgUrl:'https://mrjava.api.lmusoft.com:9000/user/getVCode?d='+new Date()*1,
      // imgUrl:'https://localhost:9000/user/getVCode?d='+new Date()*1,
      Vcode:'',
      loginInfo:{
        email:'',
        password:''
      },
      rules:{
        email: [
            { required: true,message: "电子信箱不能为空",trigger: "blur"},
            {
            validator: (rule, value, callback) => {
              if (/^\w{1,64}@[a-z0-9\-]{1,256}(\.[a-z]{2,6}){1,2}$/i.test(value) === false) {
                callback(new Error("邮箱格式错误"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }],
        password: [
          { required: true,message: "密码不能为空",trigger: "blur"},
        ]
      }
    }
  },
  created() {
    // 判断是否有token/是否已经登录 => 若有/登录 => 直接跳转到home
    isLogin().then((res) => {
      if (res.data.code === 200) {
        this.$router.replace('/main');
      }
    })
  },
  methods: {
    login(){
      axios.post(`https://mrjava.api.lmusoft.com:9000/user/login/${this.Vcode}`, this.loginInfo).then((res) => {
      // axios.post(`https://localhost:9000/user/login/${this.Vcode}`, this.loginInfo).then((res) => {
        if (res.data.code === 200) {
          /**   后续可以通过计算属性来获取数据
           *     ...mapState({
           *       userInfo: state => state.user.userInfo
           *     })
           */
          // 将userDTO和token值都存在localStorage中
          // console.log(res.data.data)
          localStorage.setItem(res.data.data.MRtoken.tokenName, res.data.data.MRtoken.tokenValue)
          localStorage.setItem("userInfo", JSON.stringify(res.data.data.userDTO))
          // 将用户信息存储到vuex中(跳过dispatch)
          this.$store.commit('user/STORAGE_USERINFO', res.data.data.userDTO)
          // 跳转到首页
          if (res.data.data.userDTO.role==1){
            this.$router.replace('/home');
          }else {
            this.$router.replace('/front');
          }

          // 登录成功输出欢迎语
          const h = this.$createElement;
          this.$message({
            type: 'success',
            message: h('p', null, [
              h('span', null, '欢迎使用每日Java'),
            ])
          });
        } else {
          this.$notify.error({
            title: '错误',
            message: res.data.message
          });
        }
      }).catch(error => {
        // console.log(error.message)
      })
    },
    resetcode(){
      this.imgUrl = 'https://mrjava.api.lmusoft.com:9000/user/getVCode?d='+new Date()*1
      // this.imgUrl = 'https://localhost:9000/user/getVCode?d='+new Date()*1
    },
    goToRegister() {
      this.$router.push('/register')
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  height: calc(100% - 73px);
  margin: 70px 180px;
  display: flex;
  justify-content: center;
  .container-left{
    margin-right: 50px;
    img{
      width: 1080px;
      height: 780px
    }
  }
  .container-right{
    display: flex;
    flex-flow: column;
    height: 795px;
    .logo-container{
      img{
        width: 400px;
        display: inline-block;
        transform: translateY(-60px)
      }
    }
    .container-bottom{
      display: inline-block;transform: translateY(-150px);
      .login-container{
        width: 400px;
        border: 1px solid #eaeaea;
        margin: 0  180px auto 0;
        padding: 35px 35px 15px 35px;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0 0 25px #cac6c6;
        box-sizing: border-box;
        .login-title{
          text-align: center;
          margin-bottom: 40px;
          color: #505458;
        }
        .login_button{
          margin-top: 10px;
          width: 200px;
          margin-left: 65px
        }
        .vcode{
          border: 1px solid #DCDFE6;
          border-radius: 5px;
          height: 38px;
          margin-left: 5px;
        }
        .gotoregister{
          cursor:pointer;
          text-decoration:none;
          margin-left: 85px;
        }
        .register{
          cursor:pointer;
          text-decoration:none;
        }
        .separator{
          height: 16px;
          border-left: 1px solid #DCDCDC;
          margin: 0 20px
        }
      }
    }
  }
}

</style>