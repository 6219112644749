<template>
  <div>
    <el-card>
      <div class="mod-role">
        <el-form :inline="true" :model="pagination" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="pagination.username" clearable placeholder="作者"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="pagination.title" clearable placeholder="博客标题"></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
                v-model="pagination.label"
                clearable
                default-first-option
                placeholder="请选择文章标签">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getAll">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
            :data="blogList" border
            style="width: 100%"
            :cell-style="{ textAlign: 'center' }"
            :header-cell-style="{textAlign: 'center'}"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-table-column prop="blogId" label="ID" width="60">
          </el-table-column>
          <el-table-column prop="title" label="标题" width="300" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="cover" label="封面" width="160">
            <template slot-scope="scope" >
              <img :src= "scope.row.cover" width= "96" height= "60"/>
            </template>
          </el-table-column>
          <el-table-column prop="username" label="作者" width="120">
        </el-table-column>
          <el-table-column prop="label" label="标签" width="120">
            <template slot-scope="scope">
              <el-tag effect="dark"> {{ scope.row.label }} </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="context" label="博客内容" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button @click="openblogs(scope.row.blogId)" size="mini">详情</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="releaseTime" label="发布时间" width="180">
          </el-table-column>
          <el-table-column prop="comment" label="评论数" width="120">
          </el-table-column>
          <el-table-column prop="watch" label="浏览量" width="120">
          </el-table-column>
          <el-table-column prop="star" label="点赞数" width="120">
          </el-table-column>
          <el-table-column prop="collection" label="收藏数" width="120">
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
<!--              <el-button size="mini" type="primary" @click="handleEdit(scope.row.blogId)" :disabled="isEdit !== scope.row.uid">编辑</el-button>-->
              <el-button size="mini" type="primary" @click="handleEdit(scope.row.blogId)">编辑</el-button>
              <el-popconfirm
                  style="margin-left: 10px"
                  confirm-button-text='取消'
                  cancel-button-text='确定'
                  @confirm="Cacneldel()"
                  @cancel="deleteBlogByLoginUser(scope.row.blogId)"
                  icon="el-icon-info"
                  icon-color="red"
                  title="是否删除该文章?一旦删除无法找回"
              >
<!--                <el-button slot="reference" size="mini" type="danger" :disabled="isEdit !== scope.row.uid">删除</el-button>-->
                <el-button slot="reference" size="mini" type="danger">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>

        <!--分页模型-->
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pagination.page"
            :page-sizes="[6,8,10,15]"
            :page-size="pagination.pageSize"
            :total="totalPage"
            layout="total,sizes,prev, pager, next,jumper"
            style="margin-top: 20px;text-align: center;"
            :hide-on-single-page="totalPage<6"
            background>
        </el-pagination>
      </div>
    </el-card>

<!--      博客详情-->
    <el-dialog
        :visible.sync="dialogVisible"
        width="70%"
        :before-close="handleClose">
<!--      博客标题-->
      <el-card>
        <div slot="header" class="clearfix">
          <span style="font-size: 32px;font-weight: 600;">{{ bloginfo.title }}</span><br>
          <div style="margin-top: 10px">
            <span style="font-size: 16px;margin-bottom: 10px;">作者: <a @click="goto(bloginfo.uid)" style="cursor:pointer;">{{ bloginfo.username }}</a></span>
            <el-button v-show="isFollow && !isMy" plain icon="el-icon-success" style="padding: 10px;margin-left: 15px" size="mini" type="success"
                       @click="myFollow">已关注
            </el-button>
            <el-button v-show="!isFollow && !isMy" plain icon="el-icon-circle-plus" style="padding: 10px;margin-left: 15px" size="mini"
                       @click="myFollow">关注
            </el-button>
          </div>
        </div>
        <p style="margin-bottom: 20px">
          <el-icon class="el-icon-time"></el-icon>
          <span style="margin-left: 5px;margin-right: 30px">{{bloginfo.releaseTime}}</span>
          <el-icon class="el-icon-copy-document"></el-icon>
          <el-tag style="margin-left: 5px;margin-right: 20px" size="mini">{{bloginfo.label}}</el-tag>
          <el-icon class="el-icon-view"></el-icon>
          <span style="margin-left: 5px;margin-right: 20px">{{bloginfo.watch}}</span>
        </p>
        <p>
          <el-button v-if="!drawer" size="mini" icon="el-icon-chat-dot-square" @click="drawer = !drawer" plain>
            {{bloginfo.comment}}
          </el-button>
          <el-button v-if="drawer" size="mini" icon="el-icon-s-comment" @click="drawer = !drawer" plain>
            {{bloginfo.comment}}
          </el-button>

          <el-button v-if="!isStar" size="mini" icon="el-icon-star-off" @click="isStar = !isStar;star(bloginfo.blogId)" plain>
            {{bloginfo.star}}
          </el-button>
          <el-button v-if="isStar" type="primary" size="mini" icon="el-icon-star-on" @click="isStar = !isStar;star(bloginfo.blogId)" plain>
            {{bloginfo.star}}
          </el-button>

          <el-button v-if="!isCollect" size="mini" icon="el-icon-collection" @click="isCollect = !isCollect;addCollect(bloginfo.blogId)" plain>
            {{bloginfo.collection}}
          </el-button>
          <el-button v-if="isCollect" type="warning" size="mini" icon="el-icon-s-management" @click="isCollect = !isCollect;addCollect(bloginfo.blogId)" plain>
            {{bloginfo.collection}}
          </el-button>

          <el-button size="mini" icon="el-icon-share" plain> 分享 </el-button>
        </p>
      </el-card>
      <el-image style="margin-top: 20px;border-radius: 5px" :src="bloginfo.cover" lazy></el-image>
<!--      博客内容-->
      <div class="article">
        <mavon-editor
        class="markdown-body wrap"
        v-highlight
        ref="md"
        codeStyle="github"
        default-open="preview"
        :ishljs="true"
        v-html="blogcontext">
        </mavon-editor>
      </div>
      <!--目录-->


    </el-dialog>

<!--      编辑博客-->
    <el-dialog :visible.sync="isEditDialog" :before-close="handleClose" width="80%">
      <el-row>
        <el-col :span="18">
          <el-input
              style="width: 660px"
              type="text"
              placeholder="请输入文章标题"
              v-model="article.title"
              clearable
              maxlength="36"
              show-word-limit>
            <template slot="prepend">✒️标题</template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-select
              style="padding-left: 15px;width: 240px"
              v-model="article.label"
              clearable
              default-first-option
              placeholder="请选择文章标签">
            <template slot="prefix">
                <span style="position: absolute;top: 5px;font-size: 18px">
                  📚
                </span>
            </template>
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <br>
      <hr>
      <mavon-editor
          style="margin-top: 20px"
          v-model="article.context"
          ref="md"
          codeStyle="github"
          :ishljs="true"
          @imgAdd="handleEditorImgAdd"
          @save="save"
          @change="change"
          class="my-editor"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">提 交</el-button>
      </span>
      <br>
    </el-dialog>

  </div>
</template>

<script>
import {
  deleteByBlogInLoginUser,
  getAllBlog,
  getBlogByBlogId, isDidStar,
  likeBlogByBlogId,
  updateBlogByLoginUser
} from "@/api/blog/blog";
// 将marked 引入
import { marked }from 'marked';
// 引入代码高亮样式
import 'mavon-editor/dist/markdown/github-markdown.min.css'
import {mavonEditor} from "mavon-editor";
import 'mavon-editor/dist/css/index.css';//引入组件的样式
import {addCollect, isCollect} from "@/api/blog/collect";
import {follow, isFollow} from "@/api/user/follow";
import {uplodadImg} from "@/api/upload/fileImg";

export default {
  name: 'BlogList',
  components: {
    mavonEditor
  },
  data() {
    return {
      blogList:[],
      pagination:{// 分页相关模型数据
        page: 1,
        username: '',
        pageSize: 6,
      },
      totalPage: 0,//总记录数
      openCard:false,
      dialogVisible:false,
      bloginfo:{},
      blogcontext:'',
      tocs: [],
      maxTitle: 6,
      openTocs: false,
      isFollow:false,//关注
      drawer: false, // 是否展开评论
      isStar: false,//点赞
      isCollect: false,//收藏
      isShare: false,//关注
      isMy:false,
      options: [
        {value: 'Java', label: 'Java'},
        {value: 'algorithm', label: 'Algorithm'},
        {value: 'essay', label: 'Essay'},
        {value: 'vue', label: 'Vue'},
        {value: 'css', label: 'Css'},
        {value: 'Python', label: 'Python'},
        {value: 'Javascript', label: 'Javascript'},
        {value: 'answer', label: 'Answer'}
      ],
      isEdit:JSON.parse(window.localStorage.getItem('userInfo')).uid,
      isEditDialog:false,
      article:{},
      imgFile:[],
      loading:true
    }
  },
  created() {
    this.getAll()
    this.initMarked()
  },
  mounted() {

  },
  methods: {
    // 初始化锚点
    initMarked() {
      let anchor = 0;
      let rendererMD = new marked.Renderer();
      let that = this
      /*
      重写标题
      text:标题文本
      level:标签
      */
      rendererMD.heading = function (text, level, raw) {
        //保存这篇文章的最大标签
        if (level < that.maxTitle) {
          that.maxTitle = level
        }
        anchor += 1
        /*
        toc:数组用于保存标题，
        id:标题id,用于点击目录滚动到改标题
        tag:记录属于那个标签（h1……h6）
        test:标签内容
        */
        that.tocs.push(
            {
              'id': anchor,
              'tag': level,
              'text': text
            }
        )
        return `<h${level} id="toc-nav${anchor}">${text}</h${level}>`;
      };
      //重写a标签，在新标签打开
      rendererMD.link = function (href, title, text) {
        return '<a href="' + href + '" title="' + text + '" target="_blank">' + text + '</a>';
      }

      marked.setOptions({
        renderer: rendererMD,
        gfm: true,//默认为true。 允许 Git Hub标准的markdown.
        tables: true,//默认为true。 允许支持表格语法。该选项要求 gfm 为true。
        breaks: false,//默认为false。 允许回车换行。该选项要求 gfm 为true。
        pedantic: false,//默认为false。 尽可能地兼容 markdown.pl的晦涩部分。不纠正原始模型任何的不良行为和错误。
        sanitize: false,//对输出进行过滤（清理）
        smartLists: true,
        smartypants: false//使用更为时髦的标点，比如在引用语法中加入破折号。
      });
    },

    // 跳转到锚点
    toTarget(target) {
      target = '#toc-nav' + target
      let toElement = document.querySelector(target);
      toElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
    },
    getAll(){
      this.loading = true
      getAllBlog(this.pagination).then(res => {
        // console.log(this.pagination)
        this.blogList = res.data.data
        this.totalPage = parseInt(res.data.message)
        this.loading = false
      })
    },
    sizeChangeHandle(val){
      this.pagination.pageSize = val;
      this.getAll();
    },
    currentChangeHandle(val){
      this.pagination.page = val;
      this.getAll();
    },
    handleClose(done){
      this.$confirm('确认关闭？')
          .then(() => {
            this.bloginfo = {}
            this.article = {}
            done();
          })
          .catch(() => {});
    },
    handleEdit(id){
      this.isEditDialog = !this.isEditDialog
      getBlogByBlogId(id).then(res => {
        this.article = res .data.data
      })
    },
    deleteBlogByLoginUser(blogId){
      deleteByBlogInLoginUser(blogId).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getAll();
        } else {
          this.$message({
            message: '删除失败',
            type: 'error'
          });
        }
      })
    },
    Cacneldel() {
      this.$message({
        message: '取消删除',
        type: 'warning'
      });
    },
    openblogs(id) {
      this.dialogVisible = !this.dialogVisible;
      getBlogByBlogId(id).then(res => {
        this.bloginfo = res.data.data
        // console.log(res.data.data)
        this.blogcontext = marked(this.bloginfo.context);
        if (this.bloginfo.uid === this.isEdit){
          //自己的博客不显示关注按钮
          this.isMy = true
        }else {
          // 查询当前用户是否已经关注过作者了并修改isFollow的值
          isFollow(this.bloginfo.uid).then((res) => {
            if (res.data.data) {
              this.isFollow = true
            }
          })
        }
        // 查询当前用户是否已经点过赞了
        isDidStar(id).then((res) => {
          this.isStar = res.data.code === 200;
        })
        // 查询当前用户是否已经收藏了
        isCollect(id).then((res) => {
          this.isCollect = res.data.data
        })
      })
    },
    // 取消关注/关注了作者
    myFollow() {
      follow(this.bloginfo.uid, !this.isFollow).then((res) => {
        if (res.data.code) {
          this.isFollow = !this.isFollow
        }
      })
    },
    goto(uid) {
      // console.log(uid)
    //   let routeData = this.$router.resolve({
    //     path: "/user",
    //     query: {
    //       uid
    //     }
    //   });
    //   window.open(routeData.href, '_blank');
    },
    // 所有操作都会被解析重新渲染
    change(value, render) {        //value为编辑器中的实际内容，即markdown的内容，render为被解析成的html的内容
      this.article.html = render;
    },
    // 提交
    submit() {
      let match = this.article.context.match(/\((.*?)\)/);
      if (match) {
        let insideParentheses = match[1];
        this.article.cover = insideParentheses
      } else {
        this.article.cover = 'https://mrjava.lmusoft.com/blog/default.jpg'
      }
      //点击提交后既可以获取html内容，又可以获得markdown的内容，之后存入到服务端就可以了
      updateBlogByLoginUser(this.article).then((res) => {
        console.log(res.data)
        if (res.data.code === 200) {
          this.isEditDialog = false
          // console.log(this.imgFile)
          this.$message({
            message: '修改成功!',
            type: 'success'
          });
          setTimeout(() => {
            location.reload()
          }, 500)
        }
      })
    },
    save(){
      let match = this.article.context.match(/\((.*?)\)/);
      if (match) {
        let insideParentheses = match[1];
        this.article.cover = insideParentheses
      } else {
        this.article.cover = 'https://mrjava.lmusoft.com/blog/default.jpg'
      }
      //点击提交后既可以获取html内容，又可以获得markdown的内容，之后存入到服务端就可以了
      updateBlogByLoginUser(this.article).then((res) => {
        if (res.data.code === 200) {
          this.isEditDialog = false
          // console.log(this.imgFile)
          this.$message({
            message: '保存成功!',
            type: 'success'
          });
        }
      })
    },
    handleEditorImgAdd(pos,$file){
      let formdata = new FormData();
      formdata.append('file', $file)
      this.imgFile[pos] = $file
      uplodadImg(formdata).then(res => {
        if (res.data.code === 200) {
          this.$message.success('上传成功')
          let url = res.data.message
          let name = $file.name
          if (name.includes('-')) {
            name = name.replace(/-/g, '')
          }
          let content = this.article.context
          // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)  这里是必须要有的
          if (content.includes(name)) {
            let oStr = `(${pos})`
            let nStr = `(${url})`
            let index = content.indexOf(oStr)
            let str = content.replace(oStr, '')
            let insertStr = (soure, start, newStr) => {
              return soure.slice(0, start) + newStr + soure.slice(start)
            }
            this.article.context = insertStr(str, index, nStr)
            // console.log(this.$refs.md.$refs.toolbar_left.$imgDelByFilename(fileName))
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    //点赞
    star(id){
      likeBlogByBlogId(id).then(res => {
        if (res.data.code === 200) {
          if (this.isStar) {
            this.bloginfo.star++;
          } else {
            this.bloginfo.star--;
          }
        } else {
          this.$message.error("点赞失败")
        }
      })
    },
    //收藏
    addCollect(id) {
      addCollect(id, this.isCollect).then((res) => {
        if (res.data.code === 200) {
          if (this.isCollect) {
            this.bloginfo.collection++;
          } else {
            this.bloginfo.collection--;
          }
        } else {
          this.$message.error("收藏失败")
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.article {
  width: 100%;
  margin: 30px auto;
  overflow: hidden;
}

/* markdown 行号的样式 */
#content li {
  list-style: decimal;
}

.article-con div pre {
  position: relative;
  padding: 0 29px;
  overflow: hidden;
  font-size: 90%;
  line-height: 1.9;
  background: #282c34;
}

.article-con div pre code {
  padding: 0.4em;
}

.article-con div .pre-numbering {
  position: absolute;
  top: 0;
  left: 0;
  width: 29px;
  padding: 9px 7px 12px 0;
  border-right: 1px solid #C3CCD0;
  background: #282c34;
  text-align: right;
  font-size: 16px;
  line-height: 1.45;
}

.pre-numbering li{
  list-style:none;
  font-family: Menlo, monospace;
  color: #abb2bf;
}
</style>