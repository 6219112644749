<template>
  <div>
    <el-menu
        default-active="1-4-1"
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
    >
      <h3>{{ isCollapse ? '后台' : '后台管理系统' }}</h3>
      <el-menu-item @click="clickMenu(item)" v-for="item in noChildren" :key="item.label" :index="item.label">
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.label }}</span>
      </el-menu-item>
      <el-submenu v-for="item in hasChildren" :key="item.label" :index="item.label">
        <template slot="title">
          <i :class="`el-icon-${item.icon}`"></i>
          <span slot="title">{{ item.label }}</span>
        </template>
        <el-menu-item-group v-for="subItem in item.children" :key="subItem.label">
          <el-menu-item @click="clickMenu(subItem)" :index="subItem.label">{{ subItem.label }}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name:'CommonAside',
  data() {
    return {
      menuData:[
        {
          path:'/',
          name:'home',
          label:'首页',
          icon:'s-home',
          url:'Home/Home'
        },
        {
          label:'商品管理',
          icon:'video-play',
          children:[
            {
              path:'/mall',
              name:'mall',
              label:'商品列表',
              icon:'setting',
              url:'MallManage/MallManage'
            },
            {
              path:'/categoty',
              name:'categoty',
              label:'类别管理',
              icon:'setting',
              url:'Category/Category'
            }
          ]
        },
        {
          label:'用户管理',
          icon:'user',
          children:[
            {
              path:'/user',
              name:'user',
              label:'用户列表',
              icon:'setting',
              url:'User/UserManage'
            },
            {
              path:'/me',
              name:'me',
              label:'个人中心',
              icon:'setting',
              url:'User/Me'
            }
          ]
        },
        {
          label:'博客管理',
          icon:'location',
          children:[
            {
              path:'/blogList',
              name:'blogList',
              label:'博客列表',
              icon:'setting',
              url:'Blog/BlogList'
            },
            {
              path:'/myBlog',
              name:'myBlog',
              label:'我的博客',
              icon:'setting',
              url:'Blog/MyBlog'
            },
            {
              path:'/writerMarkdown',
              name:'writerMarkdown',
              label:'编写博客',
              icon:'setting',
              url:'Blog/WriterMarkdown'
            }
          ]
        },
        {
          label:'其他',
          icon:'location',
          children:[
            {
              path:'/page1',
              name:'page1',
              label:'页面1',
              icon:'setting',
              url:'Other/PageOne'
            },{
              path:'/page2',
              name:'page2',
              label:'页面2',
              icon:'setting',
              url:'Other/PageTwo'
            }
          ]
        },
      ]
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    clickMenu(item){
      if (this.$route.path !== item.path && !(this.$route.path === 'home' && (item.path === '/'))){
        this.$router.push(item.path)
      }
      this.$store.commit('selectMenu',item)
    }
  },
  computed:{
    noChildren(){
      return  this.menuData.filter(item => !item.children)
    },
    hasChildren(){
      return  this.menuData.filter(item => item.children)
    },
    isCollapse(){
      return this.$store.state.tab.isCollapse
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu{
  height: 100vh;
  border-right: none;
  h3{
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
  }
}
</style>