<template>
  <div v-loading="loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <el-row :gutter="30" class="tac" style="padding: 20px 80px 0 80px">
      <el-col :span="6">
        <el-menu
            default-active="0"
            class="el-menu-vertical-demo">
          <el-menu-item index="0" @click="clickHandler(0)">
            <i class="el-icon-s-custom"></i>
            <span slot="title">全部</span>
          </el-menu-item>
          <el-menu-item @click="clickHandler(item.categoryId)" v-for="(item, index) in categoryList" :key="index" :index="item.categoryId.toString()">
            <i class="el-icon-medal"></i>
            <span slot="title">{{item.categoryName}}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="18">
        <el-row style="padding-bottom: 10px">
          <el-col style="display: flex;justify-content: space-between;">
            <span style="line-height: 40px;font-weight: 700;font-size: 20px;color: #409eff">{{ CategoryNames }}</span>
            <el-input style="width: 300px;" clearable placeholder="请输入商品名称" v-model="input">
              <el-button slot="append" @click="handleClick" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col v-if="productList.length<=0">
            <el-empty :image-size="200" description="还没有商品"></el-empty>
          </el-col>
          <el-col :span="8" v-for="(item,index) in productList" :key="index">
            <el-card class="blog-item" @click="goto(item.productId)" shadow="hover">
              <div class="imgbox">
                <img :src="item.imageUrl"  alt="">
                <label>
                  <el-tag style="color: #00B5AD;border-color: #00B5AD;" size="small" effect="plain">
                    {{ item.category.categoryName }}
                  </el-tag>
                </label>
              </div>
              <div class="titlebox">
                <a class="blogtitle" href="javascript:void(0)" @click="goto(item.productId)">{{ item.productName }}</a>
                <div class="colloection">库存：{{ item.stockQuantity }}</div>
              </div>
            </el-card>
          </el-col>
        </el-row>

        <el-divider v-if="totalPages>6"></el-divider>
        <!--分页模型-->
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pagination.page"
            :page-sizes="[6,8,10,15]"
            :page-size="pagination.pageSize"
            :total="totalPage"
            layout="total,sizes,prev, pager, next,jumper"
            style="text-align: center;"
            :hide-on-single-page="totalPage<6"
            background>
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
<script>

import {getCategory, getCategoryById, getProduct} from "@/api/product/product";

export default {
  name: 'Product',
  data() {
    return {
      categoryList: [],
      currentUid: JSON.parse(window.localStorage.getItem('userInfo')).uid,
      currid: 0,
      pagination:{// 分页相关模型数据
        page: 1,
        pageSize: 6,
        productName:'',
        categoryId:0,
        price:0
      },
      totalPage:0,
      totalPages:0,
      productList:[],
      loading : false,
      input:'',
      CategoryNames:'全部'
    }
  },
  created() {
    this.init()
  },
  methods: {
    init(){
      this.loading = true
      this.getCategory();
      this.getProduct();
    },
    getCategory(){
      getCategory().then(res => {
        // console.log(res.data)
        this.categoryList = res.data.data
      })
    },
    getProduct(){
      getProduct(this.pagination).then(res => {
        // console.log(res.data)
        this.productList = res.data.data
        this.totalPage = parseInt(res.data.message)
        this.totalPages = this.totalPage
        this.loading = false
      })
    },
    sizeChangeHandle(val){
      this.pagination.pageSize = val;
      this.getProduct();
    },
    currentChangeHandle(val){
      this.pagination.page = val;
      this.getProduct();
    },
    clickHandler(cid){
      this.pagination={
        page: 1,
        pageSize: 6,
        productName:'',
        categoryId:cid,
        price:0
      }
      if (cid !== 0){
        getCategoryById(cid).then(res => {
          // console.log(res.data)
          if (res.data.code == 200){
            this.CategoryNames = res.data.data.categoryName
          }
        })
      }else {
        this.CategoryNames = "全部"
      }

      this.getProduct()
    },
    handleClick(){
      this.pagination.productName = this.input
      // console.log(this.pagination)
      this.getProduct()
    },
    goto(id){
      this.$router.push({
        path: '/front/ShowProduct',
        query: {productId: id}
      })
    },
  }
}
</script>
<style lang="less" scoped>
.blog-item{
  display: inline-block;
  margin: 20px 20px 0;
  padding: 15px 0;

  .imgbox{
    width: 150px;
    height: 160px;
    margin: 0 auto;
    img{
      width: 150px;
      height: 150px;
      position: absolute;/*设为绝对定位*/
      //opacity: 0.7;/*设置透明度*/
      border-radius: 5px;
      transition: all 0.8s;
      cursor:pointer;
    }
    img:hover{
      transform: scale(1.1);
    }

    label{
      width: inherit;
      text-align: right;
      margin-top: -30px;
      margin-left: 25px;
      font-size: 20px;
      color: ivory;
      position: absolute;/*设为绝对定位*/
    }
  }

  .titlebox{
    width: 190px;
    height: 69px;
    margin: 10px auto 0;
    .blogtitle{
      display: block;
      height: 48px;
      font-size: 18px;
      text-decoration: none;
      color: #000000
    }
    .colloection{
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #999;
      white-space: nowrap;
      margin-top: 5px;
      height: 16px;
      line-height: 16px;
    }
  }
}
</style>