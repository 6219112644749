<template>
  <div>
    <el-card >
      <div v-if="totalPage > 0" slot="header" style="display: flex;justify-content: space-between">
        <span style="display: flex;align-items: center">我的收藏</span>
        <el-input placeholder="请输入标题" clearable v-model="pagination.title"  style="width: 300px;" type="text">
          <el-button slot="append" icon="el-icon-search" @click="init"></el-button>
        </el-input>
      </div>

      <el-card class="blog-item" v-for="(item,index) in colloectList" :key="index" shadow="hover">
        <div class="imgbox">
          <img :src="item.cover"  alt="">
          <label><el-tag style="color: #00B5AD;border-color: #00B5AD;" size="small" effect="plain">{{ item.label }}</el-tag></label>
        </div>
        <div class="titlebox">
          <a class="blogtitle" target="_blank" href="#">{{ item.title }}</a>
          <div class="colloection">收藏于：{{dateFormat('YYYY-mm-dd HH:MM',item.createTime)}}</div>
        </div>
      </el-card>
      <div v-if="totalPage <= 0" class="search-empty">
        <p>你还没有收藏，快去逛逛吧 </p>
        <el-button type="primary" round style="margin-top: 20px;width: 200px;font-size: 18px" @click="$router.push('/front')">去首页</el-button>
      </div>
      <el-divider></el-divider>
      <!--分页模型-->
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pagination.page"
          :page-sizes="[6,8,10,15]"
          :page-size="pagination.pageSize"
          :total="totalPage"
          layout="total,sizes,prev, pager, next,jumper"
          style="text-align: center;"
          :hide-on-single-page="totalPage<6"
          background>
      </el-pagination>
    </el-card>

  </div>
</template>

<script>
import {getMyCollects} from "@/api/user/collect";
import {dateFormat} from "@/utils/DateFormatting";

export default {
  name: 'MyCollect',
  data() {
    return {
      pagination:{// 分页相关模型数据
        page: 1,
        pageSize: 6,
        title: ''
      },
      totalPage: 0,//总记录数
      colloectList:[],
    }
  },
  created() {
    this.init()
  },
  methods: {
    dateFormat,
    init(){
      getMyCollects(this.pagination).then((res) => {
        this.colloectList = res.data.data
        this.totalPage = parseInt(res.data.message)
      })
    },
    sizeChangeHandle(val){
      this.pagination.pageSize = val;
      this.init();
    },
    currentChangeHandle(val){
      this.pagination.page = val;
      this.init();
    },
  },
}
</script>

<style lang="less" scoped>
.search-empty{
  width: 1100px;
  display: block;
  background-image: url(//s1.hdslb.com/bfs/static/jinkela/space/assets/nodata02.png);
  background-position: center 0px;
  background-repeat: no-repeat;
  border-radius: 4px;
  color: #6d757a;
  font-size: 14px;
  overflow: hidden;
  text-align: center;
  padding: 220px 0 80px;
}

.blog-item{
  width: 465px;
  display: inline-block;
  margin: 20px 20px 0;
  padding: 15px 0;

  .imgbox{
    width: 328px;
    height: 205px;
    margin: 0 auto;
    img{
      position: absolute;/*设为绝对定位*/
      //opacity: 0.7;/*设置透明度*/
      width: 328px;
      height: 205px;
      border-radius: 5px;
      transition: all 0.8s;
      cursor:pointer;
    }
    img:hover{
      transform: scale(1.1);
    }

    label{
      width: inherit;
      text-align: right;
      margin-left: -5px;
      font-size: 20px;
      color: ivory;
      position: absolute;/*设为绝对定位*/
    }
  }

  .titlebox{
    width: 328px;
    height: 69px;
    margin: 10px auto 0;
    .blogtitle{
      display: block;
      height: 48px;
      font-size: 18px;
      text-decoration: none;
      color: #000000
    }
    .colloection{
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #999;
      white-space: nowrap;
      margin-top: 5px;
      height: 16px;
      line-height: 16px;
    }
  }
}

</style>