<template>
  <footer class="footer">
    <div class="container">
      <img src="../assets/logos.png" width="210">
      <p>版权所有 &copy; 2024  [MrJava博客]</p>
      <ul class="footer-links">
        <li><a href="https://gitee.com"><img src="../assets/icon/gitee.svg" width="30px"/></a></li>
        <li><a href="https://github.com"><img src="../assets/icon/github.svg" width="30px"/></a></li>
        <li><a href="https://weibo.com"><img src="../assets/icon/weibo.svg" width="30px"/></a></li>
        <li><a href="https://wx.qq.com"><img src="../assets/icon/weixin.svg" width="30px"/></a></li>
      </ul>
      <ul class="footer-links">
        <li><a href="#">关于我们</a></li>
        <li><a href="#">联系我们</a></li>
        <li><a href="#">隐私政策</a></li>
      </ul>
    </div>
  </footer>
</template>
<script>
export default {
  name:'FrontFooter',
  data(){
    return{

    }
  },
  mounted() {
  },
  methods:{}
}
</script>
<style lang="less" scoped>
.footer {
  border-top: 1px silver solid;
  background-color: #f8f9fa;
  padding: 20px 0;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.footer-links li {
  display: inline-block;
  margin: 0 10px;
}

.footer-links a {
  color: #495057;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}
</style>