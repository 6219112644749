import http from "@/utils/request";

export function isFollow(blogUserId) {
    return http({
        method: 'GET',
        url: `/follow/or/not/${blogUserId}`,
    })
}

export function follow(blogUserId, isFollow) {
    return http({
        method: 'PUT',
        url: `/follow/${blogUserId}/${isFollow}`,
    })
}

export function getMyLove(params) {
    return http({
        method: 'GET',
        params:params,
        url: `/follow`,
    })
}

export function getUpdateMyLove(params) {
    return http({
        method: 'GET',
        params:params,
        url: `/follow/selectUpdate`,
    })
}

export function deleteFollow(followUserid) {
    return http({
        method: 'DELETE',
        url: `/follow/del/${followUserid}`,
    })
}

export function getFollowInfoByUid(uid) {
    return http({
        method: 'GET',
        url: `course/follow-user/${uid}`
    })
}