<template>
  <div class="container">
    <div class="container-left">
      <img src="./images/暂无记录.svg" alt="">
    </div>
    <div class="container-right">
      <div class="logo-container">
        <img src="./images/logo.png" alt="">
      </div>
      <div class="container-bottom">
        <el-form :inline="true" label-width="70px" class="login-container" :model="registerInfo">

          <h3 class="login-title">注册账号</h3>

          <el-form-item label="用户昵称" prop="email">
            <el-input v-model="registerInfo.username" placeholder="请输入用户昵称" clearable></el-input>
          </el-form-item>

          <el-form-item label="邮箱" prop="email">
            <el-input v-model="registerInfo.email" placeholder="请输入邮箱地址" clearable></el-input>
          </el-form-item>

          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="registerInfo.password" placeholder="请输入密码" clearable show-password></el-input>
            <el-popover
                placement="top-end"
                width="300"
                trigger="hover">
                <p>长度为8~14个字符</p>
                <p>字母/数字以及标点符号至少包含2种</p>
                <p>不允许有空格、中文</p>
              <i slot="reference" class="el-input__icon el-icon-warning-outline"></i>
            </el-popover>

          </el-form-item>
          <el-form-item  label="确认密码" prop="repeatPassword">
            <el-input type="password" v-model="registerInfo.repeatPassword" placeholder="请再次输入密码" clearable show-password></el-input>
          </el-form-item>

          <el-form-item label="验证码" prop="Vcode">
            <el-input v-model="Vcode" placeholder="请输入验证码" style="width: 138px;height: 42px" clearable></el-input>
            <el-button v-if="isSend" style="width: 80px;margin-left: 20px;margin-top: 0;" type="primary" @click="send">发 送</el-button>
            <el-button v-if="!isSend" style="width: 80px;margin-left: 20px;margin-top: 0;" type="primary">
              {{ sendmsg }}</el-button>
          </el-form-item>

          <el-form-item>
            <el-checkbox label="阅读并接受" name="type" v-model="agreeToTheTerms" @change="agree(agreeToTheTerms)"></el-checkbox>
            <a href="https://privacy.baidu.com/policy/children-privacy-policy/index.html?_1678523966343" target="_blank">《个人信息保护声明》</a>
          </el-form-item>

          <el-form-item style="width: 350px;">
            <el-button :disabled="disabled" type="primary" @click="register">注册</el-button>
          </el-form-item>
          <el-form-item >
            <span class="haveaccount">已有账号?</span>
            <a class="gotologin" @click="goToLogin">去登录</a>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {isLogin} from "@/api/user/user";

export default {
  name: 'Register',
  data() {
    return {
      visible:false,
      // 'https://localhost:9000/user/getVCode?d='+new Date()*1
      imgUrl:'https://mrjava.api.lmusoft.com:9000/user/getVCode?d='+new Date()*1,
      Vcode:'',
      registerInfo:{
        username:'',
        email:'',
        password:'',
        repeatPassword:''
      },
      agreeToTheTerms: false,
      disabled: true,
      sendmsg: '发送验证码',
      isSend: true,
    }
  },
  created() {
    // 判断是否有token/是否已经登录 => 若有/登录 => 直接跳转到home
    isLogin().then((res) => {
      if (res.data.code === 200) {
        this.$router.replace('/home');
      }
    })
  },
  methods: {
    agree(agreeToTheTerms) {
      this.agreeToTheTerms = agreeToTheTerms
      this.disabled = !this.disabled
    },
    goToLogin() {
      this.$router.push('/login')
    },
    register(){
      if (this.registerInfo.username === '') {
        this.$notify({
          title: '警告',
          message: '用户昵称不能为空,请输入用户昵称',
          type: 'warning'
        });
        return
      }
      if (this.registerInfo.email === '') {
        this.$notify({
          title: '警告',
          message: '邮箱号不能为空,请输入邮箱号',
          type: 'warning'
        });
        return
      }
      if (!/^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/.test(this.registerInfo.email)) {
        this.$notify.error({
          title: '错误',
          message: '你输入的邮箱号不合法,请重写输入'
        });
        return
      }
      if (this.registerInfo.password === '' || this.registerInfo.repeatPassword === '') {
        this.$notify({
          title: '警告',
          message: '密码不能为空,请输入密码',
          type: 'warning'
        });
        return
      }
      if (this.Vcode === '') {
        this.$notify({
          title: '警告',
          message: '验证码不能为空,请输入验证码',
          type: 'warning'
        });
        return
      }
      if (this.registerInfo.password !== this.registerInfo.repeatPassword) {
        this.$notify.error({
          title: '错误',
          message: '两次密码输入不一致',
        });
      } else {
        // axios.post(`https://localhost:9000/user/register/${this.Vcode}`, this.registerInfo).then((res) => {
        axios.post(`https://mrjava.api.lmusoft.com:9000/user/register/${this.Vcode}`, this.registerInfo).then((res) => {
          if (res.data.code === 200) {
            // console.log('注册成功', res.data.code, res.data.message)
            this.$message.success(res.data.message)
            this.$router.replace('/login');
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(error => {
          // console.log(error.message)
        })
      }
    },
    send() {
      if (!this.registerInfo.email) {
        Message.error("请填写邮箱后获取验证码！");
      } else if (!/^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/.test(this.registerInfo.email)) {
        Message.error("请填写正确的邮箱后获取验证码！");
      } else {
        // axios.post(`https://localhost:9000/user/sendMail`, this.registerInfo).then((res) => {
        axios.post(`https://mrjava.api.lmusoft.com:9000/user/sendMail`, this.registerInfo).then((res) => {
          if ( res.data.code === 200) {
            this.$message({
              message: '已发送，请到邮箱查看',
              type: 'success'
            })
            this.isSend = false;
            let timer = 60;
            this.sendmsg = timer + "s";
            this.timeFun = setInterval(() => {
              --timer;
              this.sendmsg = timer + "s";
              if (timer == 0) {
                this.isSend = true;
                this.sendmsg = "重新发送";
                clearInterval(this.timeFun);
              }
            }, 1000);
          }
        });
      }
    },
  },
}
</script>

<style lang="less" scoped>
p{
  font-size: 12px
}
a{
  text-decoration:none;
  color: #2E58FF
}
.container {
  height: calc(100% - 73px);
  margin: 70px 180px;
  display: flex;
  justify-content: center;
  .container-left{
    margin-right: 50px;
    img{
      width: 1080px;
      height: 780px
    }
  }
  .container-right{
    display: flex;
    flex-flow: column;
    height: 795px;
    .logo-container{
      img{
        width: 400px;
        display: inline-block;
        transform: translateY(-125px)
      }
    }
    .container-bottom{
      display: inline-block;transform: translateY(-225px);
      .login-container{
        width: 415px;
        border: 1px solid #eaeaea;
        margin: 0  180px auto 0;
        padding: 35px 35px 15px 35px;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0 0 25px #cac6c6;
        box-sizing: border-box;
        .login-title{
          text-align: center;
          margin-bottom: 40px;
          color: #505458;
        }
        .vcode{
          border: 1px solid #DCDFE6;
          border-radius: 5px;
          height: 38px;
        }
        .haveaccount{
          color: #999999;
          margin-left: 115px;
        }
        .gotologin{
          cursor:pointer;
          text-decoration:none;
          margin-left: 5px;
          color: #409EFF;
        }
        .el-input{
          width: 238px;
        }
        .el-button{
          margin-top: 10px;width: 200px;margin-left: 65px;
        }
      }
    }
  }
}
</style>