<template>
  <div class="PageTwo">
    <h1>PageTwo页面</h1>
  </div>
</template>

<script>
export default {
  name: 'PageTwo',
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
</style>