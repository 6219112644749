<template>
  <el-container>
    <el-header>
      <FrontHeader/>
    </el-header>
    <el-main style="margin: 0 134px">
      <router-view></router-view>
    </el-main>
    <el-footer>
      <FrontFooter/>
    </el-footer>
  </el-container>
</template>

<script>
import FrontHeader from "@/components/FrontHeader.vue";
import FrontFooter from "@/components/FrontFooter.vue";

export default {
  name: 'Index',
  components: {FrontFooter, FrontHeader},
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
.el-header {
  padding: 0 134px;
  background-color: #ffffff;
  line-height: 80px;
  position: relative;
}
.blog_content{
  display: flex;
  justify-content: space-between;
  .blog_content_left{
    h3{
      font-family: STSong;
      font-weight: 900;
      font-size: x-large;
    }
    .blog_content_text{
      width:480px;
      height: 90px;
      font-size: 16px;
      font-weight: 300 !important;
      letter-spacing: 1px !important;
      line-height: 1.8;
      color: rgba(0,0,0,0.6);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .blog_content_info{
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      .blog_content_info_left{
        width: 80%;
        display: flex;
        flex-direction: column;
        font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
        font-weight: 700;
        color: rgba(0,0,0,.4);
        .blog_content_info_left_user{
          margin-left: 10px;
          color: #4183C4;
          cursor: pointer;
        }
        .blog_content_info_left_top{
          display: flex;
          flex-direction: row;
          padding-bottom: 10px;
          margin-left: 10px;
        }
        .blog_content_info_left_bottom{
          display: flex;
          flex-direction: row;
          padding: 10px 0;
        }
        .blog_content_info_left_item{
          margin-left: 15px;
        }
      }
      .blog_content_info_right{
        width: 20%;
        text-align:right;
        line-height: 74px;
        margin-right: 10px;
      }
    }
  }
  .blog_content_right{
    img{
      width: 328px;
      height: 205px;
      border-radius: 5px;
      margin-left: 20px;
      transition: all 0.8s;
    }
    img:hover{
      transform: scale(1.1);
    }
  }
}
.search-empty{
  width: 1100px;
  display: block;
  background-image: url(//s1.hdslb.com/bfs/static/jinkela/space/assets/nodata02.png);
  background-position: center 120px;
  background-repeat: no-repeat;
  border-radius: 4px;
  color: #6d757a;
  font-size: 14px;
  overflow: hidden;
  text-align: center;
  padding: 340px 0 80px;
}
.search{
  width: 300px;
  margin-right: 100px;
  margin-top: 25px;
}
.label{
  width: 300px;
  margin-right: 100px;
  margin-top: 30px;
  .label_badge{
    margin-top: 15px;
    margin-right: 40px;
    cursor: pointer;
  }
  .label_tag{
    width: 120px;
    text-align: center;
    font-size: 14px;
  }
}
</style>