import http from "@/utils/request";

export function addReplyComment(data) {
    return http.post('/blog/replys', data)
}

export function deleteReply(replyId){
    return http({
        method: 'DELETE',
        url: `/blog/replys/${replyId}`
    })
}