import http from "@/utils/request";

//请求首页数据
export const getAllUser = (params) => {
    return http({
        method:'GET',
        params:params,
        url:'/users/getAll'
    })
}

export const isLogin = () => {
    return http({
        method: 'GET',
        url: '/user/is-login',
    })
}

export const loginOut = () => {
    return http({
        method: 'POST',
        url: '/user/logout',
    })
}

export function addBaseUser(addUserInfo) {
    return http.post('/users/base/insert', addUserInfo)
}

export const UpdateUserInfoByUid = (baseUserInfo) => {
    return http.put('/users/base/update',baseUserInfo)
}

export const getBaseUserInfoByUid = (uid) => {
    return http({
        method: 'GET',
        url: `/users/base/${uid}`,
    })
}

export const getUserInfoByUid = (uid) => {
    return http({
        method: 'GET',
        url: `/users/userinfo/${uid}`,
    })
}

// export const isPwd = (oldPwd) => {
//     return http({
//         method:'GET',
//         url:`/users/ispwd/${oldPwd}`
//     })
// }

export const updatePwd = (oldPwd,newPwd) => {
    return http({
        method:'PUT',
        url:`/users/update/pwd/${oldPwd}/${newPwd}`
    })
}

export const updateAvatar = (file) => {
    return http({
        method:'POST',
        url:`/users/update/avatar`,
        data: file
    })
}