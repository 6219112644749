import http from "@/utils/request";

export function getByZan() {
    return http({
        method: 'GET',
        url: `/rank/list/getByZan`
    })
}

export function getByFans() {
    return http({
        method: 'GET',
        url: `/rank/list/getByFans`
    })
}

export function getByBlogs() {
    return http({
        method: 'GET',
        url: `/rank/list/getByBlogs`
    })
}