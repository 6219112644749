<template>
  <div class="header-container">
    <div class="l-content">
      <el-button @click="handleMenu" style="margin-right: 20px" icon="el-icon-menu" size="mini"></el-button>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="item in tags" :key="item.path" :to="{ path: item.path }">{{ item.label }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="r-content">
      <el-dropdown>
        <span class="el-dropdown-link">
          <img class="user" :src="userinfo.headurl" alt="">
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="gotoFront">前台页面</el-dropdown-item>
          <el-dropdown-item @click.native="goto">个人中心</el-dropdown-item>
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState} from "vuex";
import {loginOut} from "@/api/user/user";

export default {
  name:'CommonHeader',
  data() {
    return {
      userinfo:{}
    }
  },
  mounted() {
    this.userinfo = JSON.parse(localStorage.getItem("userInfo"))
  },
  methods: {
    handleMenu(){
      this.$store.commit('collapseMenu')
    },
    logout() {
      loginOut().then(res => {
        if (res.data.code === 200) {
          this.$notify({
            title: '退出成功',
            message: '请重新登录',
            type: 'success'
          });
        }
        // 跳转到登录页面
        this.$router.replace('/login')
        // 将localStorage中的东西都删除
        localStorage.removeItem("userInfo")
        localStorage.removeItem("MRtoken")
      })
    },
    goto(){
      this.$router.replace('/me')
    },
    gotoFront(){
      this.$router.replace('/front')
    }
  },
  computed:{
    ...mapState({
        tags: state => state.tab.tabsList
    })
  }
}
</script>

<style lang="less" scoped>
.header-container{
  padding: 0 20px;
  background: #333;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text{
    color: #fff;
    font-size: 14px;
    margin-left: 10px;
  }
  .r-content{
    .user{
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  .l-content{
    display: flex;
    align-items: center;
    /deep/.el-breadcrumb__item{
      .el-breadcrumb__inner{
        font-weight: normal;
        &.is-link{
          color: #fff;
        }
      }
      &:last-child{
        .el-breadcrumb__inner{
          color: #3ed1cf;
        }
      }
    }
  }
}
</style>