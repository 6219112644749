<template>
  <div>
    <el-row>
      <el-col :span="13" style="padding-right: 10px">
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>个人资料</span>
          </div>

          <el-form label-position="right" ref="form" :model="userinfo" label-width="80px">
            <el-form-item label="头像:">
              <div style="display: flex;align-items: center">
                <el-avatar class="user-avatar" :size="65" :src="userinfo.headurl"></el-avatar>
                <el-upload
                    class="avatar-uploader"
                    action="lei"
                    :on-change="handleChange"
                    :show-file-list="false"
                    :http-request="httpRequest">
                  <el-button size="small" style="margin-left: 20px;">更换头像</el-button>
                </el-upload>
              </div>

            </el-form-item>
            <el-form-item label="昵称:">
              <el-input v-model="userinfo.username" style="width: 300px;"></el-input>
<!--              <span style="margin-left: 40px;color: #aaaaaa">注：修改一次昵称需要消耗6个硬币</span>-->
            </el-form-item>
            <el-form-item label="性别:">
              <el-radio-group v-model="userinfo.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="生日:">
              <el-date-picker type="date" placeholder="选择日期" v-model="userinfo.birthday" format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item label="邮箱:">
                <p style="color: #898989">{{userinfo.email}}</p>
            </el-form-item>
            <el-form-item label="学校/公司:">
              <el-input v-model="userinfo.schoolOrCorporation" maxlength="25" show-word-limit autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="身份:">
              <el-select v-model="userinfo.identity" placeholder="请选择身份">
                <el-option label="学生" value="学生"></el-option>
                <el-option label="打工人" value="打工人"></el-option>
                <el-option label="码农" value="码农"></el-option>
                <el-option label="开发人员" value="开发人员"></el-option>
                <el-option label="测试人员" value="测试人员"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签:">
              <el-tag
                  style="margin: 0 5px"
                  :key="tag"
                  v-for="tag in userinfo.label"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag)">
                {{ tag }}
              </el-tag>
              <el-input
                  :disabled="userinfo.label.length === 6"
                  class="input-new-tag"
                  v-if="inputVisible"
                  v-model="inputValue"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm">
              </el-input>
              <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增标签</el-button>
            </el-form-item>
            <el-form-item label="我的签名:">
              <div style="float: right">
                字体大小&nbsp;&nbsp;&nbsp;
                <el-button size="mini" @click="descriptionSize++">+</el-button>
                <el-button size="mini" @click="descriptionSize--">-</el-button>
              </div>
              <el-input
                  type="textarea"
                  :style="{fontSize: descriptionSize + 'px'}"
                  :autosize="{ minRows: 3, maxRows: 20}"
                  placeholder="请输入个人描述"
                  maxlength="1500"
                  show-word-limit
                  v-model="userinfo.description">
              </el-input>
              <!--              <el-input type="textarea" v-model="userinfo.description"></el-input>-->
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm()">更 新</el-button>
            </el-form-item>
          </el-form>

        </el-card>
      </el-col>

      <el-col :span="11" style="padding-right: 10px">
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>修改密码</span>
          </div>
          <el-form :model="pwds" status-icon :rules="rules" ref="pwds" label-width="100px">
            <el-form-item label="原始密码" prop="oldPwd">
              <el-input type="password" v-model="pwds.oldPwd" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPwd">
              <el-input type="password" v-model="pwds.newPwd" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="surePwd">
              <el-input  type="password" v-model="pwds.surePwd" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="updatePwd('pwds')">提交</el-button>
              <el-button @click="reset('pwds')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-card>

        <el-card shadow="hover" style="margin-top: 20px">
          <div slot="header" class="clearfix">
            <span>个性签名</span>
          </div>
          <p v-html="userinfo.description"></p>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {getUserInfoByUid, loginOut, updateAvatar, updatePwd, UpdateUserInfoByUid} from "@/api/user/user";
import {getTime} from "@/utils/DateFormatting";

export default {
  name: 'Personal',
  data() {
    var validatePassword = (rule, value, callback) => {
      if (value !== '') {
        if (value.length < 6) {
          callback(new Error('请输入至少6位的密码'))
          return false
        }
        //开发阶段，暂时注释
        // else if ( !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@!*#$%&_=])[A-Za-z\d@!*#$%&_=]{6,18}$/.test(value) ) {
        //   callback(new Error('密码必须包含字母、数字和特殊字符(@!*#$%&_=)'))
        //   return false
        // }
          else {
          callback()
        }
      }
    }
    var validateSurePassword = (rule, value, callback) => {
      if (value !== '') {
        if (value.length < 6) {
          callback(new Error('请输入至少6位确认密码'))
          return false
        } else if (value !== this.pwds.newPwd) {
          callback(new Error('两次输入的新密码不一致'))
          return false
        } else {
          callback()
        }
      }
    }
    return {
      userinfo:{},
      inputVisible:false,
      inputValue: '',
      descriptionSize: 18,
      pwds: {
        oldPwd:'',
        newPwd:'',
        surePwd:''
      },
      rules:{
        oldPwd: [
          { required: true, message: '请输入原始密码', trigger: 'blur' }
        ],
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: validatePassword, trigger: 'blur' }
        ],
        surePwd: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: validateSurePassword, trigger: 'blur' }
        ]
      },
      showButton: false
    }
  },
  created() {
    let uid = JSON.parse(localStorage.getItem("userInfo")).uid;
    getUserInfoByUid(uid).then((res) => {
      this.userinfo = res.data.data
      // console.log(this.userinfo)
    })
  },
  methods: {
    submitForm(){
      const re = /^\d{4}-\d{2}-\d{2}$/;
      if (!re.test(this.userinfo.birthday)){
        this.userinfo.birthday = getTime(this.userinfo.birthday)
      }
      UpdateUserInfoByUid(this.userinfo).then(res => {
        if(res.data.code === 200){
          this.$message({
            message: '修改成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: '修改失败',
            type:'error'
          })
        }
      })
      // 重新获取数据
      setTimeout(() => {
        window.location.reload()
      }, 200)
    },
    // 标签的添加
    handleClose(tag) {
      this.userinfo.label.splice(this.userinfo.label.indexOf(tag), 1);
    },
    // 自动获取焦点
    showInput() {
      this.inputVisible = true;
      this.$nextTick( () => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.userinfo.label.push(inputValue);
      }

      if (inputValue.length >= 10) {
        this.$notify({
          title: '警告',
          message: '标签长度不超过10',
          type: 'warning'
        });
        this.userinfo.label.pop()
        return
      }

      if (this.userinfo.label.length >= 6) {
        this.$notify({
          title: '警告',
          message: '最多可以添加6个标签',
          type: 'warning'
        });
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    updatePwd(formName){
      this.$confirm('密码修改成功将重新登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            updatePwd(this.pwds.oldPwd,this.pwds.newPwd).then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  showClose: true,
                  message: '修改成功',
                  type: 'success'
                });
                this.logout();
              } else {
                this.$message({
                  showClose: true,
                  message: '修改失败, 未知原因',
                  type: 'error'
                });
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改'
        });
      });
    },
    logout() {
      loginOut().then(res => {
        if (res.data.code === 200) {
          this.$notify({
            title: '退出成功',
            message: '请重新登录',
            type: 'success'
          });
        }
        // 跳转到登录页面
        this.$router.replace('/login')
        // 将localStorage中的东西都删除
        localStorage.removeItem("userInfo")
        localStorage.removeItem("MRtoken")
      })
    },
    reset(formName) {
      this.$refs[formName].resetFields();
    },
    //将上传图片的原路径赋值给临时路径
    handleChange(file, fileList) {
      this.tempUrl = URL.createObjectURL(file.raw);
    },
    //实现图片上传功能
    httpRequest(item) {
      //验证图片格式大小信息
      const isJPG = item.file.type == 'image/jpeg' || item.file.type == 'image/png';
      const isLt2M = item.file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      //图片格式大小信息没问题 执行上传图片的方法
      if (isJPG && isLt2M == true) {
        //定义FormData对象 存储文件
        let mf = new FormData();
        //将图片文件放入mf
        mf.append('file', item.file);
        updateAvatar(mf).then(res=>{
          if(res.data.code == 200){
            this.$message({
              message: '更换成功',
              type: 'success'
            });
            let userInfoObj = JSON.parse(localStorage.getItem("userInfo"))
            userInfoObj.headurl = res.data.message;
            localStorage.setItem("userInfo", JSON.stringify(userInfoObj));
            location.reload()
          }else {
            this.$message({
              message: '更换失败',
              type: 'error'
            });
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.user-avatar{
  margin-left: 10px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
</style>