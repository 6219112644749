<template>
  <el-row>
    <el-col :span="8" style="padding-right: 10px">
      <el-card>
        <div class="user">
          <img :src="userinfo.headurl" alt="">
          <div class="userinfo">
            <p class="name">{{ userinfo.username }}</p>
            <p class="access">超级管理员</p>
          </div>
        </div>
        <div class="login-info">
          <p>上次登录时间：<span>{{ MyDate }}</span></p>
          <p>上次登录地点：<span>{{ ipinfo.city }}</span></p>
        </div>
      </el-card>
      <el-card style="margin-top: 20px;height: 460px">
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column v-for="(val,key) in tableLabel" :key="key" :prop="key" :label="val"/>
        </el-table>
      </el-card>
    </el-col>
    <el-col :span="16" style="padding-left: 10px">
      <div class="num">
        <el-card v-for="item in countData" :key="item.name" :body-style="{display:'flex',padding:0}">
          <i class="icon" :class="`el-icon-${item.icon}`" :style="{background:item.color}"></i>
          <div class="detail">
            <p class="price">{{item.value}}</p>
            <p class="desc">{{item.name}}</p>
          </div>
        </el-card>
      </div>
      <el-card style="height: 280px">
        <div ref="line" style="height: 280px;"></div>
      </el-card>
      <div class="graph">
        <el-card style="height: 260px">
          <div ref="bar" style="height: 260px;"></div>
        </el-card>
        <el-card style="height: 260px">
          <div ref="pie" style="height: 240px"></div>
        </el-card>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import * as echarts from 'echarts'
import {getTime} from "@/utils/DateFormatting";
import {jsonp} from "vue-jsonp";

export default {
  name:'Home',
  data() {
    return {
      tableData:[
        {
          name:'oppo',
          todayBuy:200,
          monthBuy:300,
          totalBuy:800
        },
        {
          name:'vivo',
          todayBuy:100,
          monthBuy:300,
          totalBuy:800
        },
        {
          name:'苹果',
          todayBuy:100,
          monthBuy:300,
          totalBuy:800
        },
        {
          name:'小米',
          todayBuy:100,
          monthBuy:300,
          totalBuy:800
        },
        {
          name:'三星',
          todayBuy:100,
          monthBuy:300,
          totalBuy:800
        },
        {
          name:'魅族',
          todayBuy:100,
          monthBuy:300,
          totalBuy:800
        },
      ],
      tableLabel:{
        name:'课程',
        todayBuy:'今日购买',
        monthBuy:'本月购买',
        totalBuy:'总购买'
      },
      countData:[
        {
          name:'今日支付订单',
          value:1234,
          icon:'success',
          color:'#2ec7c9'
        },
        {
          name:'今日收藏订单',
          value:1234,
          icon:'star-on',
          color:'#ffb980'
        },
        {
          name:'今日未支付订单',
          value:1234,
          icon:'s-goods',
          color:'#5ab1ef'
        },
        {
          name:'本月支付订单',
          value:1234,
          icon:'success',
          color:'#2ec7c9'
        },
        {
          name:'本月收藏订单',
          value:1234,
          icon:'star-on',
          color:'#ffb980'
        },
        {
          name:'本月未支付订单',
          value:1234,
          icon:'s-goods',
          color:'#5ab1ef'
        }
      ],
      orderData:{
        data:[
          {
            苹果:234,
            vivo:2297,
            oppo:3816,
            魅族:4733,
            三星:4203,
            小米:6151
          },
          {
            苹果:2705,
            vivo:2894,
            oppo:978,
            魅族:3465,
            三星:6151,
            小米:4733
          },
          {
            苹果:913,
            vivo:912,
            oppo:7157,
            魅族:5081,
            三星:6597,
            小米:4343
          },
          {
            苹果:3007,
            vivo:2279,
            oppo:7928,
            魅族:2174,
            三星:1160,
            小米:3338
          },
          {
            苹果:3928,
            vivo:6343,
            oppo:5480,
            魅族:3338,
            三星:4343,
            小米:6151
          },
          {
            苹果:4701,
            vivo:2135,
            oppo:4045,
            魅族:4596,
            三星:5234,
            小米:2894
          },
          {
            苹果:3837,
            vivo:1360,
            oppo:4753,
            魅族:2753,
            三星:5937,
            小米:3456
          },
        ],
        date:['20230501','20230502','20230503','20230504','20230505','20230506','20230507',]
      },
      userData:[
        {
          date:'周一',
          new:5,
          active:200
        },
        {
          date:'周二',
          new:10,
          active:500
        },
        {
          date:'周三',
          new:12,
          active:550
        },
        {
          date:'周四',
          new:60,
          active:800
        },
        {
          date:'周五',
          new:65,
          active:550
        },
        {
          date:'周六',
          new:53,
          active:770
        },
        {
          date:'周日',
          new:33,
          active:170
        },
      ],
      videoData:[
        {
          name:'小米',
          value:2999
        },
        {
          name:'苹果',
          value:5999
        },
        {
          name:'vivo',
          value:1500
        },
        {
          name:'oppo',
          value:1999
        },
        {
          name:'魅族',
          value:2200
        },
        {
          name:'三星',
          value:4500
        },
      ],
      MyDate:getTime(new Date()),
      userinfo: {},
      ipinfo:{}
    }
  },
  mounted() {
    this.userinfo = JSON.parse(localStorage.getItem("userInfo"))
    if(this.userinfo.role !== 1){
      this.$router.replace('/front');
    }
    this.getUserIpConfig()

    // 折线图
    const line = echarts.init(this.$refs.line)
    var lineOption = {}
    lineOption.tooltip = {
      trigger:'axis'
    }
    const xAxis = Object.keys(this.orderData.data[0])
    const legendData = {
      data: xAxis
    }
    const xAxisData = {
      data:this.orderData.date
    }
    lineOption.xAxis = xAxisData
    lineOption.yAxis = {}
    lineOption.legend = legendData
    lineOption.series = []
    xAxis.forEach(key => {
      lineOption.series.push({
        name:key,
        data:this.orderData.data.map(item => item[key]),
        type: 'line'
      })
    })
    // console.log(lineOption)
    line.setOption(lineOption);

    //   柱状图
    const bar = echarts.init(this.$refs.bar)
    const barOption = {
      legend:{
        textStyle:{
          color:'#333'
        }
      },
      grid:{
        left:'20%'
      },
      tooltip:{
        trigger: 'axis'
      },
      xAxis:{
        type:'category',
        data:this.userData.map(item => item.date),
        axisLine:{
          lineStyle:{
            color:'#17b3a3'
          }
        },
        axisLabel:{
          interval:0,
          color:'#333'
        }
      },
      yAxis:[
        {
          type: 'value',
          axisLine:{
            lineStyle: {
              color:'#17b3a3'
            }
          }
        }
      ],
      color:["#2ec7c9","#b6a2de"],
      series:[
        {
          name:'新增用户',
          data:this.userData.map(item => item.new),
          type:'bar'
        },
        {
          name:'活跃用户',
          data: this.userData.map(item => item.active),
          type:'bar'
        }
      ]
    }
    bar.setOption(barOption);

    //   饼状图
    const pie = echarts.init(this.$refs.pie)
    const pieOption = {
      tooltip:{
        trigger:'item'
      },
      color:[
        '#0f78f4',
        '#dd536b',
        '#9462e5',
        '#a6a6a6',
        '#e1bb22',
        '#39c362',
        '#3ed1cf'
      ],
      series:[
        {
          data:this.videoData,
          type:'pie'
        }
      ]
    }
    pie.setOption(pieOption);
  },
  methods: {
    getUserIpConfig(){
      return new Promise((resolve, reject) => {
        jsonp('https://apis.map.qq.com/ws/location/v1/ip?key=HS2BZ-FSDKG-QM5Q3-QMUZB-CYB43-U7BD3', {
          output: 'jsonp'
        }).then(resp => {
          resolve(resp)

          this.ipinfo = resp.result.ad_info
          // console.log(this.ipinfo)
        }).catch(err => {
          console.log(err);
        })
      })
    }
  },
}
</script>

<style lang="less" scoped>
.user{
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  img{
    margin-right: 40px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .userinfo{
    .name{
      font-size: 32px;
      margin-bottom: 10px;
    }
    .access{
      color: #999999;
    }
  }
}
.login-info{
  p{
    line-height: 28px;
    font-size: 14px;
    color: #999999;
    span{
      color: #666666;
      margin-left: 60px;
    }
  }
}
.num{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .icon{
    width: 80px;
    height: 80px;
    font-size: 30px;
    text-align: center;
    line-height: 80px;
    color: #fff;
  }
  .detail{
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .price{
      font-size: 30px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 30px;
    }
    .desc{
      font-size: 14px;
      color: #999999;
      text-align: center;
    }
  }
  .el-card{
    width: 32%;
    margin-bottom: 20px;
  }
}
.graph{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .el-card{
    width: 48%;
  }
}
</style>