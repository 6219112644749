import http from "@/utils/request";

export function getCommentsByBlogId(blogId) {
    return http({
        method: 'GET',
        url: `/blog/comments/${blogId}`
    })
}

export function addComment(data) {
    return http.post('/blog/comments', data)
}

export function deleteComment(commentId){
    return http({
        method: 'DELETE',
        url: `/blog/comments/${commentId}`
    })
}
