<template>
  <div class="PageOne">
    <h1>PageOne页面</h1>
  </div>
</template>

<script>
export default {
  name: 'PageOne',
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
</style>