import axios from "axios";
import {Message} from "element-ui";

const http = axios.create({
    // 本地
    // baseURL:'https://localhost:9000',
    // 线上
    baseURL:'https://mrjava.api.lmusoft.com:9000',
    // timeout:10000
})

// 请求拦截器
http.interceptors.request.use((config) => {
    const token = localStorage.getItem("MRtoken")
    token ? config.headers["MRtoken"] = token : null;
    return config;
},(error) => {
    Message.error("请求错误")
    return Promise.reject(error);
});

// 响应拦截器
http.interceptors.response.use((response) => {
    return response;
},(error) => {
    Message.error("响应错误")
    return Promise.reject(error);
});

export default http