<template>
  <div>
    <el-card style="margin-top: 10px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="个人资料" name="first" lazy>
          <personal/>
        </el-tab-pane>
        <el-tab-pane label="我的博客" name="second" lazy>
          <my-blog/>
        </el-tab-pane>
        <el-tab-pane label="我的收藏" name="third" lazy>
          <my-collect/>
        </el-tab-pane>
        <el-tab-pane label="我的关注" name="fourth" lazy>
          <my-follow/>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import MyBlog from "@/views/Blog/MyBlog.vue";
import Personal from "@/views/Users/Me/Personal.vue";
import MyFollow from "@/views/Users/Me/MyFollow.vue";
import MyCollect from "@/views/Users/Me/MyCollect.vue";

export default {
  name: 'Me',
  data() {
    return {
      activeName: 'first'
    }
  },
  components:{
    Personal,
    MyBlog,
    MyCollect,
    MyFollow
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  },
}
</script>

<style lang="less" scoped>
.el-tab-pane{
  padding-bottom: 20px;
}
</style>