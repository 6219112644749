import http from "@/utils/request";

export function getLabelCount() {
    return http({
        method: 'GET',
        url: `/blog/countLabel`,
    })
}

export function getCountAllLabel() {
    return http({
        method: 'GET',
        url: `/blog/countAllLabel`,
    })
}