<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="less">
html,body{
  margin: 0;
  padding: 0;
  height: 100%;
}
h3,p{
  margin: 0;
  padding: 0;
}
.el-popover{
  background-color: rgba(0,0,0,0.7);
  border-color: rgba(0,0,0,0.1);
  color: #ffffff;
}
.el-popover[x-placement^=top] .popper__arrow{
  border-top-color: rgba(0,0,0,0.5);
}
.el-popover[x-placement^=top] .popper__arrow::after{
  border-top-color: rgba(0,0,0,0.5);
}
</style>
