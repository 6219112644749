<template>
  <div>
    <el-card>
      <div class="follow">
        <div class="follow-sidenav">
          <div class="nav-container">
            <span class="nav-title">我的关注</span>
          </div>
          <div class="follow-list" @click="clickMyFollow" :style="followColor">
            <span class="follow-icon"><el-icon class="el-icon-s-custom" ></el-icon></span>
            <span class="follow-item">全部关注</span>
            <span class="num">{{ totalPage }}</span>
          </div>

          <div class="nav-container">
            <span class="nav-title">我的粉丝</span>
          </div>
          <div class="follow-list" @click="clickMyFans" :style="fansColor">
            <span class="follow-icon"><el-icon class="el-icon-user" ></el-icon></span>
            <span class="follow-item">我的粉丝</span>
            <span class="num">0</span>
          </div>
        </div>
<!--        我的关注-->
        <div v-show="!showFollow" class="follow-main">
          <div class="follow-header">
            <p class="follow-main-title">全部关注</p>
            <el-input placeholder="请输入内容" v-model="pagination.username" clearable  style="width: 300px">
              <el-button slot="append" icon="el-icon-search" @click="searchMyFollow"></el-button>
            </el-input>
          </div>

          <div class="follow-content">
            <ul class="relation-list">
              <li class="list-item" v-for="item in myFollow" :key="item.uid">
                <div class="cover-container">
                  <a href="#" class="up-cover-components">
                    <div style="width: 100%;height: 100%">
                      <img class="avatar-img" :src="item.headurl">
                    </div>
                  </a>
                </div>
                <div class="content">
                  <a href="#" class="con-title">
                    <span style="color: rgb(251, 114, 153);">{{ item.username }}</span>
                  </a>
                  <div class="con-label">
                    <el-tag size="mini" v-for="(item2,index) in item.label" :key="index" style="margin-right: 10px" >{{ item2 }}</el-tag>
                  </div>
                  <p v-show="item.description" class="auth-description">{{ item.description }}</p>
                  <p v-show="!item.description" class="auth-description">这个人没有填简介啊~~~</p>
                  <div class="fans-action">
                    <el-button v-if="!item.isSure" @click="sureFollow(item.uid,true)" size="small" icon="el-icon-circle-plus" type="info" plain>关注</el-button>
                    <el-button v-if="item.isSure" @click="sureFollow(item.uid,false)" size="small" icon="el-icon-success" type="success" plain>已关注</el-button>
                  </div>
                </div>
              </li>
            </ul>
            <div v-show="this.myFollow.length < 1" class="search-empty">
              <p>你还没关注别人哦</p>
            </div>
          </div>
          <!--分页模型-->
          <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pagination.page"
              :page-sizes="[6,8,10,15]"
              :page-size="pagination.pageSize"
              :total="totalPage"
              layout="total,sizes,prev, pager, next,jumper"
              style="text-align: center;padding-bottom: 20px"
              :hide-on-single-page="totalPage<6"
              background>
          </el-pagination>
        </div>

<!--        我的粉丝-->
        <div v-show="showFollow" class="follow-main">
          <div class="follow-header">
            <p class="follow-main-title">我的粉丝</p>
          </div>

          <div class="follow-content">
            <ul class="relation-list">
              <li class="list-item" v-for="item in myFans" :key="item.uid">
                <div class="cover-container">
                  <a href="#" class="up-cover-components">
                    <div style="width: 100%;height: 100%">
                      <img class="avatar-img" :src="item.headurl">
                    </div>
                  </a>
                </div>
                <div class="content">
                  <a href="#" class="con-title">
                    <span style="color: rgb(251, 114, 153);">{{ item.username }}</span>
                  </a>
                  <div class="con-label">
                    <el-tag size="mini" v-for="(item2,index) in item.label" :key="index" style="margin-right: 10px" >{{ item2 }}</el-tag>
                  </div>
                  <p class="auth-description">{{ item.description }}</p>
                  <div class="fans-action">
                    <el-button v-show="!isFans" size="small" icon="el-icon-circle-plus" type="info" plain>回关</el-button>
                    <el-button v-show="isFans" size="small" icon="el-icon-success" type="success" plain>已关注</el-button>
                  </div>
                </div>
              </li>
            </ul>
            <div v-show="totalFans <= 0" class="search-empty">
              <p>你还没有粉丝哦~</p>
            </div>
          </div>
          <!--分页模型-->
          <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pagination.page"
              :page-sizes="[6,8,10,15]"
              :page-size="pagination.pageSize"
              :total="totalFans"
              layout="total,sizes,prev, pager, next,jumper"
              :hide-on-single-page="totalFans<6"
              style="text-align: center;padding-bottom: 20px"
              background>
          </el-pagination>
        </div>
      </div>

    </el-card>
  </div>
</template>

<script>
import {deleteFollow, follow, getMyLove, getUpdateMyLove} from "@/api/user/follow";

export default {
  name: 'MyFollow',
  data() {
    return {
      isFollow:true,
      isFans:true,
      myFollow:[],
      myFans:[],
      pagination:{// 分页相关模型数据
        page: 1,
        pageSize: 6,
        username:''
      },
      totalPage: 0,//关注数
      totalFans:0,//粉丝数
      showFollow:false,
      followColor:{
        backgroundColor:'#00a1d6',
        color:'#FFFFFF'
      },
      fansColor:{},
      updateuid:false,
    }
  },
  provide(){
    return{
      reload: this.reload
    }
  },
  created() {
    getMyLove(this.pagination).then((res) => {
      this.myFollow = res.data.data
      this.totalPage = parseInt(res.data.message)
    })
  },
  methods: {
    reload() {
      this.isFollow = false;
      this.$nextTick(() =>{
        this.isFollow = true
      })
    },
    sizeChangeHandle(val){
      this.pagination.pageSize = val;
      this.getDataList();
    },
    currentChangeHandle(val){
      this.pagination.page = val;
      this.getDataList();
    },
    clickMyFollow(){
      this.showFollow = false
      this.followColor={
        backgroundColor:'#00a1d6',
        color:'#FFFFFF'
      }
      this.fansColor={}
    },
    clickMyFans(){
      this.showFollow = true
      this.followColor={}
      this.fansColor={
        backgroundColor:'#00a1d6',
        color:'#FFFFFF'
      }
    },
    searchMyFollow(){
      getMyLove(this.pagination).then((res) => {
        this.myFollow = res.data.data
      })
    },
    // 取消关注/关注了作者
    sureFollow(uid,isFollow) {
      follow(uid, isFollow).then((res) => {
        if (res.data.code) {
          if(isFollow){
            getMyLove(this.pagination).then((res) => {
              this.myFollow = res.data.data
              this.totalPage = parseInt(res.data.message)
              this.$message({
                message: '你关注了该用户',
                type: 'success'
              });
            })
          }else {
            //更新数据
            getUpdateMyLove(this.pagination).then((res) => {
              this.myFollow = res.data.data
            })
            setTimeout(() => {
              //更新数量
              getMyLove(this.pagination).then((res) => {
                this.totalPage = parseInt(res.data.message)
              })
              deleteFollow(uid).then((res) => {
                if (res.data.code){
                  this.$message({
                    message: '你已取关该用户'
                  });
                }
              })
            }, 1000)
          }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.follow{
  display: flex;
  justify-content: space-between;

  .follow-sidenav{
    width: 20%;
    border: 1px solid #eee;
    border-right: 1px;

    .nav-container{
      position: relative;
      height: 24px;
      padding: 20px 0 0 20px;

      .nav-title{
        color: #99a2aa;
        font-size: 14px
      }
    }

    .follow-list{
      padding-left: 20px;
      border-bottom: 1px solid #eee;

      .follow-icon{
        width: 20px;
        height: 17px;
        margin-right: 6px;
        vertical-align: middle;
      }

      .follow-item{
        display: inline-block;
        line-height: 44px;
        width: 65%;
        margin-right: 10px;
        font-size: 14px;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }

      .num{
        display: inline-block;
        width: 50px;
        font-size: 12px;
        vertical-align: middle;
        text-align: center;
      }
    }
  }

  .follow-main{
    width: 80%;
    border: 1px solid #eee;

    .follow-header{
      margin: 20px 20px 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #e5e9ef;
      display: flex;
      justify-content: space-between;

      .follow-main-title{
        font-size: 18px;line-height: 40px
      }
    }

    .follow-content{
      border-bottom: none;
      margin-bottom: 0;
      position: relative;

      .relation-list{
        padding: 0 20px 20px;

        .list-item{
          border-bottom: 1px solid #eee;
          display: block;
          padding: 20px 0;
          position: relative;
          line-height: 1;

          .cover-container{
            margin-top: 10px;
            position: absolute;
            width: 70px;
            height: 70px;

            .up-cover-components{
              width: 70px;
              height: 70px;
              position: absolute;

              .avatar-img{
                width: 70px!important;
                height: 70px!important;
                border-radius: 50%;
              }
            }
          }

          .content{
            padding: 0 200px 0 0;
            position: relative;
            margin-left: 88px;
            margin-top: 10px;

            .con-title{
              font-size: 16px;
              text-decoration:none;
              line-height: 19px;
              height: 20px;
              margin-bottom: 10px;
              display: inline-block;
              max-width: 680px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .con-label{
              margin-bottom: 10px;
            }

            .auth-description{
              line-height: 14px;
              font-size: 12px;
              color: #6d757a;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .fans-action{
              position: absolute;
              right: 0;
              top: 19px;
              border-radius: 4px;
              font-size: 0;
              color: #6d757a;
              padding: 4px 11px 4px 9px;
              line-height: 16px;
              border: 0;
            }
          }
        }
      }

      .search-empty{
        display: block;
        background-image: url(//s1.hdslb.com/bfs/static/jinkela/space/assets/nodata02.png);
        background-position: center 120px;
        background-repeat: no-repeat;
        border-radius: 4px;
        color: #6d757a;
        font-size: 14px;
        overflow: hidden;
        text-align: center;
        padding: 340px 0 80px;
      }
    }

  }
}
</style>