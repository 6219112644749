<template>
  <div class="main-content" v-loading="loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div style="height: 20px;"></div>
    <div style="width: 60%;background-color: white;margin: 0 auto;border-radius: 20px">
      <div style="height: 50px;padding-left: 20px;padding-top: 20px">
        <el-button type="warning" round plain @click="goback">
          <i class="el-icon-back" style="margin-right: 5px"></i>
          返回
        </el-button>
      </div>
      <div style="padding: 15px 20px">
        <el-row :gutter="20">
          <el-col :span="12">
            <img :src="productinfo.imageUrl" style="width: 100%;height: 340px;border-radius: 20px;border: #cccccc 1px solid">
          </el-col>
          <el-col :span="12">
            <div style="font-size: 20px;font-weight: 900;overflow:hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical">{{productinfo.productName}}</div>
            <div style="color: #666666;margin-top: 5px;">库存：{{productinfo.stockQuantity}}</div>
            <div style="color:red;margin-top: 15px;">价格：<span style="font-size: 20px;">{{ productinfo.price }} / 件</span></div>
            <div style="margin-top: 20px;">
              <img src="../assets/logos.png" style="width: 100%;height: 130px;border-radius: 15px;border: #cccccc 1px solid">
            </div>
            <div style="margin-top: 20px">
              <el-button style="width: 150px;" type="danger" @click="pay(productinfo.productId,productinfo.productName,productinfo.price,productinfo.description)">购买</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="padding: 15px 20px;">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="商品描述" name="first">
            <div style="padding: 10px 75px;">
              <span v-html="productinfo.description"></span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="商品评价" name="second">
            评价待开发
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>

import {getProductById, payProduct} from "@/api/product/product";
import axios from "axios";

export default {
  name: 'ShowProduct',
  data() {

    return {
      pid:'',
      activeName:'first',
      productinfo:{},
      loading:false
    }
  },
  mounted() {
    this.getDatainfo()
  },
  methods: {
    handleClick(tab,event){
      this.activeName = tab.name
    },
    navTo(url){
      this.$router.push(url)
    },
    getDatainfo(){
      this.loading = true
      let pid = this.$route.query.productId
      getProductById(pid).then(res => {
        // console.log(res.data)
        if(res.data.code === 200){
          this.productinfo = res.data.data
          this.loading = false
        }
      })
    },
    goback(){
      this.$router.go (-1)
    },
    pay(outTradeNo, subject, totalAmount, body) {
      // console.log(outTradeNo, subject, totalAmount, body)
      let num = Math.floor(Math.random() * 9000) + 1000;
      outTradeNo = outTradeNo+num
      console.log(outTradeNo)
      axios.post(
          // "https://localhost:9000/order/alipay?outTradeNo=" +
          "https://mrjava.api.lmusoft.com:9000/order/alipay?outTradeNo=" +
          outTradeNo +
          "&subject=" +
          subject +
          "&totalAmount=" +
          totalAmount +
          "&body=" +
          body
      ).then((resp) => {
        payProduct(outTradeNo).then(res=>{
          if (res.data.code==200){
            // console.log("购买成功")
          }
        })
        // 添加之前先删除一下，如果单页面，页面不刷新，添加进去的内容会一直保留在页面中，二次调用form表单会出错
        const divForm = document.getElementsByTagName("div");
        if (divForm.length) {
          document.body.removeChild(divForm[0]);
        }
        const div = document.createElement("div");
        div.innerHTML = resp.data; // data就是接口返回的form 表单字符串
        document.body.appendChild(div);
        document.forms[0].setAttribute("target",""); // 当前窗口跳转
        document.forms[0].submit();

      });
    },
  }
}
</script>

<style lang="less" scoped>
#tab-first,#tab-second{
  font-size: 16px !important;
}
</style>