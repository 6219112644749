<template>
  <div style="margin-top: 20px">
    <el-card>
      <el-row>
        <el-col :span="18">
          <el-input
              style="width: 860px"
              type="text"
              placeholder="请输入文章标题"
              v-model="article.title"
              clearable
              maxlength="36"
              show-word-limit>
            <template slot="prepend">✒️标题</template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-select
              style="padding-left: 15px;width: 290px"
              v-model="article.label"
              clearable
              default-first-option
              placeholder="请选择文章标签">
            <template slot="prefix">
                <span style="position: absolute;top: 5px;font-size: 18px">
                  📚
                </span>
            </template>
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <br>
      <hr>
      <mavon-editor
          v-model="article.context"
          ref="md"
          codeStyle="github"
          :ishljs="true"
          @imgAdd="handleEditorImgAdd"
          @imgDel="handleEditorImgDel"
          @change="change"
          class="my-editor"
      />
      <el-button type="primary" style="float: right;margin: 20px 0" @click="submit">提 交</el-button>
      <br>
    </el-card>
  </div>
</template>

<script>
import {mavonEditor} from 'mavon-editor';
import 'mavon-editor/dist/markdown/github-markdown.min.css'
import 'mavon-editor/dist/css/index.css';//引入组件的样式
import {addBlog} from "@/api/blog/blog";
import {deleteImg, uplodadImg} from "@/api/upload/fileImg";
export default {
  name: 'WriterMarkdown',
  // 注册组件
  components: {
    mavonEditor,
  },
  data() {
    return {
      article: {
        title: '',
        label: '',
        cover:'',
        context: '# :one:请开始你的创造:smiley:',
        html: ''     // 实时转化的html内容
      },
      options: [
          {value: 'Java', label: 'Java'},
          {value: 'algorithm', label: 'algorithm'},
          {value: 'essay', label: 'essay'},
          {value: 'vue', label: 'vue'},
          {value: 'css', label: 'css'},
          {value: 'Python', label: 'Python'},
          {value: 'Javascript', label: 'Javascript'},
          {value: 'answer', label: '解题'},
          {value: 'other', label: '其它'},
      ],
      imgFile:[],
      oldImgs:[]
    }
  },
  methods: {
    handleEditorImgAdd(pos,$file){
      let formdata = new FormData();
      formdata.append('file', $file)
      this.imgFile[pos] = $file
      uplodadImg(formdata).then(res => {
        if (res.data.code === 200) {
          this.$message.success('上传成功')
          let url = res.data.message
          this.oldImgs.push(res.data.message)
          this.oldImgs.forEach(img=>{
            // console.log(img)
          })
          let name = $file.name
          if (name.includes('-')) {
            name = name.replace(/-/g, '')
          }
          let content = this.article.context
          // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)  这里是必须要有的
          if (content.includes(name)) {
            let oStr = `(${pos})`
            let nStr = `(${url})`
            let index = content.indexOf(oStr)
            let str = content.replace(oStr, '')
            let insertStr = (soure, start, newStr) => {
              return soure.slice(0, start) + newStr + soure.slice(start)
            }
            this.article.context = insertStr(str, index, nStr)
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleEditorImgDel(pos){
      delete this.imgFile[pos]
    },
    // 所有操作都会被解析重新渲染
    change(value, render) {        //value为编辑器中的实际内容，即markdown的内容，render为被解析成的html的内容
      this.article.html = render;
    },
    submit(){
      if (this.article.title === '') {
        this.$notify({
          title: '警告',
          message: '文章标题不能为空',
          type: 'warning'
        });
        return
      }
      if (this.article.label === '') {
        this.$notify({
          title: '警告',
          message: '文章标签不能为空',
          type: 'warning'
        });
        return;
      }

      let newText = this.article.context
      let pattern = /\((.*?)\)/g;
      let newMatcher = newText.match(pattern);
      let newImgList = [];
      if (newMatcher) {
        newMatcher.forEach(match => {
          newImgList.push(match.slice(1, match.length - 1));
        });
      }
      let oldImgList = this.oldImgs;
      oldImgList = oldImgList.filter(item =>!newImgList.includes(item));
      oldImgList.forEach(img => {
        let index = img.indexOf("blog/");
        if (index!== -1) {
          img = img.substring(index+5);
        }
        deleteImg(img).then(res=>{})
      });

      if (newImgList) {
        // console.log(newImgList[0])
        this.article.cover = newImgList[0];
      } else {
        this.article.cover = 'https://mrjava.lmusoft.com/blog/default.jpg'
      }
      addBlog(this.article).then((res) => {
        if (res.data.code === 200) {
          this.$notify({
            title: '成功',
            message: '文章提交成功 ! ',
            type: 'success'
          });
          // 跳转我的博客页面
          setTimeout(() => {
            this.$router.replace("/front/me")
          }, 500)
        } else {
          this.$notify({
            title: '失败',
            message: '文章提交成功',
            type: 'error'
          });
        }
      })
    },
  },
}
</script>

<style scoped>
</style>