<template>
  <div style="margin-top: 20px">
    <!--      博客标题-->
    <el-card>
      <div slot="header" class="clearfix">
        <span style="font-size: 32px;font-weight: 600;">{{ bloginfo.title }}</span>
        <el-button style="float: right; padding: 3px 0;font-size: 16px" type="text" @click="goback()">
          <el-icon class="el-icon-back"></el-icon>
          返回
        </el-button>
        <br>
        <div style="margin-top: 10px">
          <span style="font-size: 16px;margin-bottom: 10px">作者: <a @click="goto(bloginfo.uid)" style="cursor:pointer;">{{ bloginfo.username }}</a></span>
          <el-button v-show="isFollow && !isMy" plain icon="el-icon-success" type="success" style="padding: 10px;margin-left: 15px" size="mini"
                     @click="myFollow">已关注
          </el-button>
          <el-button v-show="!isFollow && !isMy" plain icon="el-icon-circle-plus" style="padding: 10px;margin-left: 15px" size="mini"
                     @click="myFollow">关注
          </el-button>
        </div>
      </div>
      <p style="margin-bottom: 20px">
        <el-icon class="el-icon-time"></el-icon>
        <span style="margin-left: 5px;margin-right: 30px">{{bloginfo.releaseTime}}</span>
        <el-icon class="el-icon-copy-document"></el-icon>
        <el-tag style="margin-left: 5px;margin-right: 20px" size="mini">{{bloginfo.label}}</el-tag>
        <el-icon class="el-icon-view"></el-icon>
        <span style="margin-left: 5px;margin-right: 20px">{{bloginfo.watch}}</span>
      </p>
      <p>
        <el-button size="mini" icon="el-icon-chat-dot-square" @click="drawer = !drawer;getComments()" plain>
          {{ commentCount }}
        </el-button>

        <el-button v-if="!isStar" size="mini" icon="el-icon-star-off" @click="isStar = !isStar;star()" plain>
          {{bloginfo.star}}
        </el-button>
        <el-button v-if="isStar" type="primary" size="mini" icon="el-icon-star-on" @click="isStar = !isStar;star()" plain>
          {{bloginfo.star}}
        </el-button>

        <el-button v-if="!isCollect" size="mini" icon="el-icon-collection" @click="isCollect = !isCollect;addCollect()" plain>
          {{bloginfo.collection}}
        </el-button>
        <el-button v-if="isCollect" type="warning" size="mini" icon="el-icon-s-management" @click="isCollect = !isCollect;addCollect()" plain>
          {{bloginfo.collection}}
        </el-button>

        <el-button size="mini" icon="el-icon-share" plain> 分享 </el-button>
      </p>
    </el-card>
    <div style="text-align: center">
      <img style="margin : 20px auto;width: 100%;height: 300px;border-radius: 5px" :src="bloginfo.cover" :onerror="defaultImg"/>
    </div>
    <!--      博客内容-->
    <div class="article">
      <mavon-editor
          class="markdown-body wrap"
          v-highlight
          ref="md"
          codeStyle="github"
          default-open="preview"
          :ishljs="true"
          v-html="blogcontext">
      </mavon-editor>
      <!--        <div v-highlight v-html="blogcontext" class="markdown-body" id="content"></div>-->
    </div>
<!--    博客评论-->
    <el-drawer
        :modal="false"
        size="40%"
        :title="'评论 '+commentCount"
        :before-close="handleClose"
        :visible.sync="drawer">
      <div>
        <hr>
        <!--      评论输入框-->
        <div v-if="!isReply" class="comment-input">
          <div class="input-avatar">
            <img :src="currentAvatar" alt="loginUserImg">
          </div>
          <div class="input-container">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 10}"
                placeholder="欢迎您高质量的评论"
                maxlength="200"
                show-word-limit
                v-model="content">
            </el-input>
          </div>
          <el-button class="sub-btn" type="primary" @click="submit">发布</el-button>
        </div>
        <!--        回复输入框-->
        <div v-else class="reply-input">
          <div class="reply-img">
            <img :src="currentAvatar" alt="loginUserImg">
          </div>
          <div class="reply-input-text">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 10}"
                :placeholder="`回复 ${replayToName}：`"
                maxlength="200"
                show-word-limit
                v-model="replyContent">
            </el-input>
          </div>
          <el-button class="reply-sub" type="primary">回复</el-button>
        </div>
        <!--      评论列表-->
        <div v-for="(UserComment,index) in UserComments" :key="UserComment.id">
          <!--        评论内容-->
          <div class="comment-container">
            <div class="comment-avatar">
              <img :src="UserComment.headurl" alt="">
            </div>
            <div class="comment-box">
              <div class="comment-username">{{UserComment.username}}</div>
              <div class="comment-text">
                <span>{{UserComment.content}}</span>
                <div class="comment-time">
                  <span class="time">{{String(timestampToTime(UserComment.releaseTime))}}</span>
                  <span class="reply-btn" @click="clickReply(UserComment.username,UserComment.id,UserComment.uid)">回复</span>
                  <span v-show="currentUid === UserComment.uid" class="comment-flag">
                  <el-popconfirm
                      title="删除评论后，评论下所有回复都会被删除，是否继续？"
                      @confirm="deleteComment(UserComment.id)"
                      @cancel="cancelComment()"
                  >
                    <span slot="reference">删除</span>
                  </el-popconfirm>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!--        回复列表-->
          <div v-show="showReply===index" class="reply-box" v-for="item in UserComment.userReplys" :key="item.id">
            <div class="reply-item" >
              <div class="reply-avatar">
                <img :src="item.headurl" alt="">
              </div>
              <div class="reply-container">
                <div class="reply-text">
                  <span class="reply-username">{{item.username}}</span>
                  <span class="reply-username" v-if="UserComment.uid !==item.toUid">
                    <i class="el-icon-caret-right"></i>
                    {{item.toUsername}}
                  </span>
                  <span class="reply-content">{{item.content}}</span>
                </div>
                <div class="reply-time">
                  <div class="sub-reply-time">
                    <span class="sub-time">{{String(timestampToTime(item.releaseTime))}}</span>
                    <span class="reply-btn" @click="clickReply(item.username,item.commentId,item.replyUid)">回复</span>
                    <el-popconfirm
                        title="确定要删除该评论？"
                        @confirm="deleteReply(item.id)"
                        @cancel="cancelComment()"
                    >
                      <span slot="reference" v-show="currentUid === item.replyUid" class="reply-flag">删除</span>
                    </el-popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          统计数量-->
          <div class="reply-count" v-show="UserComment.userReplys">
            <span>共{{ UserComment.value }}条回复，</span>
            <span v-if="showReply!==index" class="reply-btn" @click="expandReplys(index);">点击展开<i class="el-icon-arrow-down" style="margin-left: 2px;"></i></span>
            <span v-else class="reply-btn" @click="closeReplys()">点击收起 <i class="el-icon-arrow-up" style="margin-left: 2px;"></i></span>
          </div>
        </div>
<!--        style="background-color: rgba(220,38,38,0)"-->
        <el-drawer
            size="14%"
            title="我是标题"
            :append-to-body="true"
            :visible.sync="isReply"
            direction="btt"
            :with-header="false">
<!--          <div style="width: 650px;height: 120px;float: left"></div>-->
          <!--        回复输入框-->
          <div v-show="isReply" class="reply-input" style="margin-top: 20px;margin-left: 400px">
            <div class="reply-img">
              <img :src="currentAvatar" alt="loginUserImg">
            </div>
            <div class="reply-input-text" style="width: 800px">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 10}"
                  :placeholder="`回复 ${replayToName}：`"
                  maxlength="200"
                  show-word-limit
                  v-model="replyContent">
              </el-input>
            </div>
            <el-button class="reply-sub" type="primary" @click="reply">回复</el-button>
          </div>
        </el-drawer>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {addWatch, getBlogByBlogId, isDidStar, likeBlogByBlogId} from "@/api/blog/blog";
import {marked} from "marked";
import {addCollect, isCollect} from "@/api/blog/collect";
import {follow, isFollow} from "@/api/user/follow";
import {addComment, deleteComment, getCommentsByBlogId} from "@/api/blog/comment";
import {timestampToTime} from "@/utils/DateFormatting";
import {addReplyComment, deleteReply} from "@/api/blog/reply";

export default {
  name: 'ShowBlog',
  data() {
    return {
      dialogVisible:true,
      bloginfo : {},
      blogcontext:'',
      article : {},
      isFollow:false,//关注
      drawer: false, // 是否展开评论
      isStar: false,//点赞
      isCollect: false,//收藏
      isShare: false,//关注
      isMy:false,
      defaultImg:'this.src="'+require('@/assets/loading.jpg')+'"',
      blogId: this.$route.query.blogId,
      currentUid:JSON.parse(window.localStorage.getItem('userInfo')).uid,
      currentAvatar:JSON.parse(window.localStorage.getItem('userInfo')).headurl,
      content: '',      // 当前登录用户写的评论
      UserComments: [], // 存放用户的评论
      sumComment:0,
      replayToName:'',
      commentId:0,
      replyToUid:0,
      isReply:false,
      showReply:-1,
      replyContent:''    //用户回复内容
    }
  },
  computed:{
    commentCount() {
      let num = 0
      for (let i=0;i<this.UserComments.length;i++){
        num += this.UserComments[i].value
      }
      return this.UserComments.length + num
    },
  },
  created() {
    this.getBlogByBlogId();
    this.getComments()

    // 查询当前用户是否已经点过赞了
    isDidStar(this.blogId).then((res) => {
      this.isStar = res.data.code === 200;
    })

    // 查询当前用户是否已经收藏了
    isCollect(this.blogId).then((res) => {
      this.isCollect = res.data.data
    })
  },
  mounted() {
    addWatch(this.blogId).then()
  },
  methods: {
    // 将评论时间戳转化为yyyy-mm-dd格式
    timestampToTime,
    getBlogByBlogId(){
      const id = this.$route.query.blogId
      getBlogByBlogId(id).then(res => {
        this.bloginfo = res .data.data
        this.blogcontext = marked(this.bloginfo.context);

        if (this.bloginfo.uid === this.currentUid){
          //自己的博客不显示关注按钮
          this.isMy = true
        }else {
          // 查询当前用户是否已经关注过作者了并修改isFollow的值
          isFollow(this.bloginfo.uid).then((res) => {
            if (res.data.data) {
              this.isFollow = true
            }
          })
        }

      })
    },
    handleClose(done){
      this.$confirm('确认关闭？')
          .then(() => {
            // this.drawer = !this.drawer
            // this.isReply = !this.isReply
            done();
          })
          .catch(() => {});
    },
    // 取消关注/关注了作者
    myFollow() {
      follow(this.bloginfo.uid, !this.isFollow).then((res) => {
        if (res.data.code) {
          this.isFollow = !this.isFollow
        }
      })
    },
    goto(uid) {
      // console.log(uid)
    },
    goback(){
      this.$router.go(-1)
    },
    //点赞
    star(){
      likeBlogByBlogId(this.blogId).then(res => {
        if (res.data.code === 200) {
          if (this.isStar) {
            this.bloginfo.star++;
          } else {
            this.bloginfo.star--;
          }
        } else {
          this.$message.error("点赞失败")
        }
      })
    },
    //收藏
    addCollect() {
      addCollect(this.blogId, this.isCollect).then((res) => {
        if (res.data.code === 200) {
          if (this.isCollect) {
            this.bloginfo.collection++;
          } else {
            this.bloginfo.collection--;
          }
        } else {
          this.$message.error("收藏失败")
        }
      })
    },
    // 获取评论信息
    getComments() {
      getCommentsByBlogId(this.blogId).then((res) => {
        this.UserComments = res.data.data
      })
    },

// 提交用户评论
    submit() {
      let content = this.content
      let blogId = this.blogId

      let formData = {
        content,
        blogId
      }
      addComment(formData).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: '评论成功',
            type: 'success'
          });
          setTimeout(() => {
            // 重新获取评论的信息
            this.getComments();
          }, 300)
          // 清空评论
          this.content = ''
        } else {
          this.$message({
            message: '评论失败',
            type: 'error'
          });
        }
      })
    },
//     删除用户评论
    deleteComment(cid){
      deleteComment(cid).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.closeReplys()
          this.getComments()
        } else {
          this.$message({
            message: '删除失败',
            type: 'error'
          });
        }
      })
    },
//     取消删除评论
    cancelComment(){
      this.$message({
        message: '取消删除该评论',
        type: 'info'
      });
    },
//    提交回复
    reply(){
      let commentId = this.commentId
      let toUid = this.replyToUid
      let content = this.replyContent
      let toUsername = this.replayToName

      let formData = {
        commentId,
        toUid,
        content,
        toUsername
      }
      addReplyComment(formData).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: '回复成功',
            type: 'success'
          });
          this.isReply = false
          this.replyContent = ''
          setTimeout(() => {
            // 重新获取评论的信息
            this.getComments();
          }, 300)
          // 清空评论
          this.content = ''
        } else {
          this.$message({
            message: '回复失败',
            type: 'error'
          });
        }
      })
    },
    // 删除回复
    deleteReply(rid){
      deleteReply(rid).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getComments()
        } else {
          this.$message({
            message: '删除失败',
            type: 'error'
          });
        }
      })
    },
    // 展开回复
    expandReplys(index){
      this.showReply = index
    },
    // 收起回复
    closeReplys(){
      this.showReply = -1
    },
// 点击用户头像进行跳转到另外一个用户个人描述页面
    gotoUserInfo(uid) {
      // console.log(uid)
    },
    clickReply(replyName,commentId,replyToUid){
      // console.log(replyName,commentId,replyToUid)
      this.replayToName = replyName
      this.commentId = commentId
      this.replyToUid = replyToUid
      this.isReply = true
    }
  }
}
</script>

<style lang="less" scoped>
.article {
  width: 100%;
  margin: 30px auto;
  overflow: hidden;
}

/* markdown 行号的样式 */
#content li {
  list-style: decimal;
}

.article-con div pre {
  position: relative;
  padding: 0 29px;
  overflow: hidden;
  font-size: 90%;
  line-height: 1.9;
  background: #282c34;
}

.article-con div pre code {
  padding: 0.4em;
}

.article-con div .pre-numbering {
  position: absolute;
  top: 0;
  left: 0;
  width: 29px;
  padding: 9px 7px 12px 0;
  border-right: 1px solid #C3CCD0;
  background: #282c34;
  text-align: right;
  font-size: 16px;
  line-height: 1.45;
}

.pre-numbering li{
  list-style:none;
  font-family: Menlo, monospace;
  color: #abb2bf;
}

.reply-btn:hover{
  cursor: pointer;
  color: #00AEEC;
}

.comment-input{
  margin-top: 10px;
  padding-bottom: 20px;
  display: flex;

  .input-avatar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    margin-top: 10px;

    img{
      width: 48px;
      height: 48px;
      border-radius: 50%
    }
  }

  .input-container{
    display: flex;
    padding-top: 10px;
    width: 520px;
  }

  .sub-btn{
    margin-left: 10px;
    margin-top: 10px;
    font-size: 16px
  }
}

.reply-input{
  margin-top: 10px;
  padding-bottom: 20px;
  display: flex;

  .reply-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    margin-top: 10px;

    img{
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }

  .reply-input-text{
    display: flex;
    padding-top: 10px;
    width: 520px;
  }

  .reply-sub{
    margin-left: 10px;
    margin-top: 10px;
    font-size: 16px
  }
}

.comment-container{
  padding: 22px 0 0 15px;
  display: flex;

  .comment-avatar{
    width: 48px;
    height: 48px;

    img{
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }

  .comment-flag{
    display:none;
  }

  .comment-box:hover{
    border-radius: 5px;
    background: #EAEFFF;
    .comment-flag{
      display:inline;
      margin-left: 20px;
      color: red;
      cursor: pointer;
    }
  }

  .comment-box{
    width: 100%;
    margin-left: 15px;

    .comment-username{
      font-size: 14px;
      color: #61666D;
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }

    .comment-text{
      font-size: 16px;
      line-height: 26px
    }

    .comment-time{
      display: flex;
      align-items: center;
      margin-top: 2px;
      font-size: 13px;
      color: #9499a0;

      .time{
        margin-right: 20px
      }
    }
  }
}

.reply-box{
  padding-left: calc(85px - 8px);

  .reply-item{
    display: flex;
    padding: 8px 0;

    .reply-avatar{
      width: 30px;
      height: 30px;

      img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }

    .reply-flag{
      display:none;
    }

    .reply-container:hover{
      border-radius: 5px;
      background: #EAEFFF;
      .reply-flag{
        display:inline;
        margin-left: 20px;
        color: red;
        cursor: pointer;
      }
    }

    .reply-container{
      width: 100%;
      margin-left: 5px;

      .reply-text{
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        .reply-username{
          font-size: 14px;
          color: #61666D;
          line-height: 26px;
          margin-right: 9px;
        }

        .reply-content{
          font-size: 16px;
          line-height: 24px
        }
      }

      .reply-time{
        font-size: 16px;line-height: 26px;

        .sub-reply-time{
          display: flex;
          align-items: center;
          margin-top: 2px;
          font-size: 13px;
          color: #9499a0;

          .sub-time{
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.reply-count{
  padding-left: calc(85px - 8px);
  color: #9499A0;
  font-size: 13px;
}



.line{
  margin-left: 80px;
  border-bottom: 1px solid #e3e5e7;
  margin-top: 14px;
}
</style>