<template>
  <div>
    <div class="rank-banner">
      <span style="margin-left: 100px"><span style="color:#fc5531;">MR</span>排行榜</span>
    </div>
    <el-row :gutter="30" class="tac" style="padding: 0 80px">
      <el-col :span="6">
        <el-menu
            default-active="2"
            class="el-menu-vertical-demo">
          <el-menu-item @click="getByZan" index="2">
            <i class="el-icon-medal-1"></i>
            <span slot="title">热门榜</span>
          </el-menu-item>
          <el-menu-item @click="getByFans" index="3">
            <i class="el-icon-s-custom"></i>
            <span slot="title">人气榜</span>
          </el-menu-item>
          <el-menu-item @click="getByBlogs" index="4">
            <i class="el-icon-trophy"></i>
            <span slot="title">贡献榜</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="18">
        <div class="follow-content">
          <ul class="relation-list">
            <li class="list-item" v-for="(item,index) in userList" :key="index">
              <div style="width: 80px;text-align: center;line-height: 80px">
                <span v-if="index+1<4" style="color: #fc5031;font-weight: 900;font-style: italic;font-size: 36px">{{index+1}}</span>
                <span v-else style="color: #999999;font-weight: 900;font-style: italic;">{{index+1}}</span>
              </div>
              <div class="cover-container">
                <a href="#" class="up-cover-components">
                  <div style="width: 100%;height: 100%">
                    <img class="avatar-img" :src="item.headurl">
                  </div>
                </a>
              </div>
              <div class="content">
                <div class="content-left">
                  <a href="#" class="con-title">
                    <span style="color: rgb(251, 114, 153);">{{ item.username }}</span>
                  </a>
                  <div class="con-label">
                    <el-tag size="mini" style="margin-right: 10px" >粉丝数：{{ item.fans }}</el-tag>
                    <el-tag size="mini" style="margin-right: 10px" >获赞：{{ item.zan }}</el-tag>
                  </div>
                </div>
<!--                <p class="auth-description">获赞：{{ item.zan }}</p>-->
                <div class="fans-action" v-show="currentUid !== item.uid">
                  <el-button v-if="item.isfoll !== 1" @click="sureFollow(item.uid,true)" size="small" icon="el-icon-circle-plus" type="info" plain>关注</el-button>
                  <el-button v-else @click="sureFollow(item.uid,false)" size="small" icon="el-icon-success" type="success" plain>已关注</el-button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import FrontHeader from "@/components/FrontHeader.vue";
import {getByBlogs, getByFans, getByZan} from "@/api/rank/rank";
import {deleteFollow, follow, getMyLove} from "@/api/user/follow";
export default {
  name: 'Rank',
  components: {FrontHeader},
  data() {
    return {
      userList:[],
      currentUid:JSON.parse(window.localStorage.getItem('userInfo')).uid,
      currid: 0,
      isFollows:[],
      ranks:1
    }
  },
  created() {
    this.getByZan()
  },
  methods: {
    getByZan(){
      this.ranks = 1
      getByZan().then(res=>{
        this.userList = res.data.data
      })

    },
    getByFans(){
      this.ranks = 2
      getByFans().then(res=>{
        this.userList = res.data.data
      })
    },
    getByBlogs(){
      this.ranks = 3
      getByBlogs().then(res=>{
        this.userList = res.data.data
      })
    },
    reloads(){
      let n = this.ranks
      if (n==1){
        this.getByZan()
      }else if(n==2){
        this.getByFans()
      }else if(n==3){
        this.getByBlogs()
      }
    },
    sureFollow(uid,isFollow) {
      follow(uid, isFollow).then((res) => {
        if (res.data.code) {
          if(isFollow){
            getMyLove(this.pagination).then((res) => {
              this.$message({
                message: '你关注了该用户',
                type: 'success'
              });
            })
            this.reloads()
          }else {
            setTimeout(() => {
              deleteFollow(uid).then((res) => {
                if (res.data.code){
                  this.$message({
                    message: '你已取关该用户'
                  });
                  this.reloads()
                }
              })
            }, 1000)
          }
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.rank-banner{
  height: 100px;
  margin: 20px 0;
  background-image: url("../../assets/rank_banner.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 1920px 100px;
  color: #ffffff;
  font-weight: 900;
  font-size: 36px;
  line-height: 100px;
  letter-spacing: 5px;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}

.follow-content{
  border-bottom: none;
  margin-bottom: 0;
  //position: relative;

  .relation-list{
    padding: 0 20px 20px;

    .list-item{
      border-bottom: 1px solid #eee;
      display: block;
      padding: 20px 0;
      //position: relative;
      line-height: 1;
      display: flex;
      //justify-content: space-between;
      flex-wrap: nowrap;

      .cover-container{
        margin-top: 10px;
        //position: absolute;
        width: 70px;
        height: 70px;

        .up-cover-components{
          width: 70px;
          height: 70px;
          //position: absolute;

          .avatar-img{
            width: 70px!important;
            height: 70px!important;
            border-radius: 50%;
          }
        }
      }

      .content{
        width: 80%;
        //padding: 0 100px 0 20px;
        //position: relative;
        //margin-left: 88px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .content-left{
          padding: 0 20px;
          .con-title{
            font-size: 16px;
            text-decoration:none;
            line-height: 19px;
            height: 20px;
            margin-bottom: 10px;
            display: inline-block;
            max-width: 680px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .con-label{
            margin-bottom: 10px;
          }

          .auth-description{
            line-height: 14px;
            font-size: 12px;
            color: #6d757a;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .fans-action{
          //position: absolute;
          //right: 0;
          //top: 19px;
          border-radius: 4px;
          font-size: 0;
          color: #6d757a;
          padding: 4px 11px 4px 9px;
          line-height: 16px;
          border: 0;
        }
      }
    }
  }

  .search-empty{
    display: block;
    background-image: url(//s1.hdslb.com/bfs/static/jinkela/space/assets/nodata02.png);
    background-position: center 120px;
    background-repeat: no-repeat;
    border-radius: 4px;
    color: #6d757a;
    font-size: 14px;
    overflow: hidden;
    text-align: center;
    padding: 340px 0 80px;
  }
}


</style>