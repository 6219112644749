<template>
  <div class="header-cotnainer">
    <div class="l-content">
      <img src="../assets/logos.png" width="146">
      <ul id="nav" v-for="item in tags" :key="item.path">
        <a href="javascript:void(0)" @click="goto(item.path)">{{item.label}}</a>
      </ul>
      <el-button icon="el-icon-circle-plus-outline" type="primary" round style="margin-left: 80px"
      @click="goto('/front/writerMarkdown')">
        发布
      </el-button>
    </div>
    <div class="r-content">
      <el-dropdown>
        <span class="el-dropdown-link">
          <img class="user" :src="userinfo.headurl" alt="">
        </span>
        <el-dropdown-menu slot="dropdown" style="margin-top: -30px">
          <el-dropdown-item @click.native="goto('/')" v-if="userinfo.role===1">后台管理</el-dropdown-item>
          <el-dropdown-item @click.native="goto('/front/me')">个人中心</el-dropdown-item>
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
  </div>
</template>

<script>
import {loginOut} from "@/api/user/user";

export default {
  name:'FrontHeader',
  data() {
    return {
      userinfo:{},
      tags:[
        {
          label:'博客',
          path:'/front/index'
        },
        {
          label:'产品周边',
          path:'/front/product'
        },
        {
          label: '排行榜',
          path: '/front/rank'
        },
        {
          label:'个人中心',
          path:'/front/me'
        },
      ]
    }
  },
  mounted() {
    this.userinfo = JSON.parse(localStorage.getItem("userInfo"))
  },
  methods: {
    logout() {
      loginOut().then(res => {
        if (res.data.code === 200) {
          this.$notify({
            title: '退出成功',
            message: '请重新登录',
            type: 'success'
          });
        }
        // 跳转到登录页面
        this.$router.replace('/login')
        // 将localStorage中的东西都删除
        localStorage.removeItem("userInfo")
        localStorage.removeItem("MRtoken")
      })
    },
    goto(path){
      this.$router.replace(path)
    }
  },
  computed:{

  }
}
</script>

<style lang="less" scoped>
#nav {
  margin-left: 40px;
  height: 80px;
  //color: #1989FA;
}
#nav ul {
  list-style: none;
  margin-left: 50px;
}
#nav li {
  display: inline;
}
#nav a {
  line-height: 80px;
  color: #409eff;
  text-decoration: none;
  padding: 0px 20px;
}
#nav a:hover {
  color: #ffffff;
  background-color: #1989FA;
}

.header-cotnainer{
  width: 80%;
  position: fixed;
  top: 0;
  z-index:999;
  padding: 0 20px;
  background-color: #ffffff;
  height: 80px;
  color: #333;
  line-height: 80px;
  border-bottom: 1px silver solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text{
    color: #fff;
    font-size: 14px;
    margin-left: 10px;
  }
  .r-content{
    height: 80px;
    .user{
      width: 60px;
      height: 60px;
      margin-top: 10px;
      border-radius: 50%;
    }
  }
  .l-content{
    display: flex;
    align-items: center;
    /deep/.el-breadcrumb__item{
      .el-breadcrumb__inner{
        font-weight: normal;
        &.is-link{
          color: #fff;
        }
      }
      &:last-child{
        .el-breadcrumb__inner{
          color: #3ed1cf;
        }
      }
    }
  }
}
</style>