<template>
  <div>
    <el-card>
      <div class="mod-role">
        <el-table
            :data="categoryList" border
            style="width: 100%"
            :cell-style="{ textAlign: 'center' }"
            :header-cell-style="{textAlign: 'center'}"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-table-column prop="categoryId" label="CID">
          </el-table-column>
          <el-table-column prop="categoryName" label="类别名称">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="openDialog()">新增</el-button>
              <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="handleDel(scope.row.categoryId)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--分页模型-->
<!--        <el-pagination-->
<!--            @size-change="sizeChangeHandle"-->
<!--            @current-change="currentChangeHandle"-->
<!--            :current-page="pagination.page"-->
<!--            :page-sizes="[6,8,10,15]"-->
<!--            :page-size="pagination.pageSize"-->
<!--            :total="totalPage"-->
<!--            layout="total,sizes,prev, pager, next,jumper"-->
<!--            style="margin-top: 20px;text-align: center;"-->
<!--            :hide-on-single-page="totalPage<6"-->
<!--            background>-->
<!--        </el-pagination>-->
      </div>
    </el-card>

    <!--新增对话框-->
    <el-dialog title="新增类别信息" :visible.sync="addDialogFormVisible" :before-close="closeDialog">
      <el-form :model="addCategoryInfo">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" :label-width="formLabelWidth">
              <el-input v-model="addCategoryInfo.categoryName" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="addCategory">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改类别信息" :visible.sync="dialogFormVisible" :before-close="cancel">

      <el-form :model="categoryinfo" ref="categoryinfo">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" :label-width="formLabelWidth">
              <el-input v-model="categoryinfo.categoryName" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addCategory,
  deleteCategory,
  getCategory,
  updateCategory,
} from "@/api/product/product";

export default {
  name: 'Category',
  data() {
    return {
      categoryList:[],
      pagination:{
        categoryName: '',
      },
      loading:false,
      addDialogFormVisible:false,
      formLabelWidth: '100px',
      addCategoryInfo:{},
      dialogFormVisible:false,
      categoryinfo:{},
      rawData:{},
      diffData:{},
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList(){
      this.loading = true
      getCategory().then(res=>{
        if(res.data.code === 200){
          this.categoryList = res.data.data
          this.loading = false
        }
      })
    },
    openDialog(){
      this.addDialogFormVisible = true
      this.addCategoryInfo = {
        categoryName:''
      }
    },
    handleEdit(row){
      this.dialogFormVisible = true
      this.categoryinfo = row;
      this.rawData = { ...row }
    },
    handleDel(id){
      deleteCategory(id).then( res => {
        if(res.data.code === 200){
          this.$message({
            message: "删除成功",
            type: 'success'
          })
        } else {
          this.$message({
            message: res.data.message,
            type:'error'
          })
        }
      })
    },
    closeDialog(){
      this.addDialogFormVisible = false
    },
    addCategory(){
      this.addDialogFormVisible = false
      addCategory(this.addCategoryInfo).then(res => {
        if(res.data.code === 200){
          this.$message({
            message: "添加成功",
            type: 'success'
          })
        } else {
          this.$message({
            message: res.data.message,
            type:'error'
          })
          this.closeDialog()
        }
      })
      this.dataReload()
    },
    dataReload() {
      // 重新获取数据
      setTimeout(() => {
        window.location.reload()
      }, 200)
    },
    cancel() {
      this.dialogFormVisible = false
      this.$message({
        message: '取消修改',
        type: 'warning'
      });
    },
    diffFormData () {
      for (let k in this.rawData) {
        if (this.rawData[k] !== this.categoryinfo[k]) {
          if (!this.diffData) {
            this.diffData = {}
          }
          this.diffData[k] = this.categoryinfo[k]
        }
      }
    },
    sure(){
      this.dialogFormVisible = false
      this.diffFormData()
      if (!this.diffData.categoryName) {
        this.$message.warning('未修改任何数据，无需提交')
      } else {
        this.diffData.categoryId = this.categoryinfo.categoryId
        updateCategory(this.diffData).then(res => {
          if(res.data.code === 200){
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: '修改失败',
              type:'error'
            })
          }
        })
      }
      this.dataReload()
    },
  }
}
</script>

<style lang="less" scoped>
</style>